import { TypographyProps } from "@/components/customized-ui/typography/type"
import { cn } from "@/lib/utils"

export function TypographyP2({ children, className, ...rest }: TypographyProps<'p'>) {
  return (
    <p
      className={cn("text-sm text-neutral-700", className)}
      {...rest}
    >
      {children}
    </p>
  )
}
  