import { TypographyProps } from "@/components/customized-ui/typography/type"
import { cn } from "@/lib/utils"

export function TypographyCaption({ children, className, ...rest }: TypographyProps<'p'>) {
  return (
    <p
      className={cn("text-xs font-normal", className)}
      {...rest}
    >
      {children}
    </p>
  )
}
  