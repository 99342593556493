import React, { useEffect, useRef } from "react";
import { format } from "date-fns";
import { IoLocationOutline } from "react-icons/io5";
import CustomTabs from "@/components/customized-ui/tab/base-tab";
import CustomCard from "@/components/customized-ui/card/custom-card";
import { Button } from "@/components/ui/button";
import { JobDetail } from "@/types/common/job";
import { removeSpecialCharacters } from "@/utils/helpers/string-helper";
import { TypographyCaption } from "../typography/caption";
import { MdOutlineWorkOutline } from "react-icons/md";
import { LiaIndustrySolid } from "react-icons/lia";
import { TbFunction } from "react-icons/tb";
import { FaBusinessTime } from "react-icons/fa6";


function formatTextWithLineBreaks(text: string) {
  const paragraphs = removeSpecialCharacters(text).split("\n");

  const formattedParagraphs = paragraphs.map((paragraph: string, index: number) => (
    <p key={index} className="text-[#656565] text-sm whitespace-pre-wrap">{paragraph}</p>
  ));

  return formattedParagraphs;
}

const JobDetailSectionWrapper = ({ title, children }: { title: string, children: React.ReactNode }) => (
  <div className="relative py-1 px-2 border-b border-[#D9D9D9]">
    <h2 className="text-lg font-semibold text-[#555555]">{title}</h2>
    <div className="py-2 overflow-auto">{children}</div>
  </div>
);

function JobDetailBox({ jobDetail }: { jobDetail: JobDetail }) {
  const { jobInfo, keepCourseList } = jobDetail;
  const jobDetailRef = useRef({} as any);

  const scrollToTop = () => {
    if (!jobDetailRef?.current?.scroll) return;
    jobDetailRef?.current?.scroll({
      top: 0,
    });
  };

  useEffect(() => {
    scrollToTop();
  }, [jobDetail]);

  return (
    <>
      <div
        className="relative flex flex-col h-full bg-[#FFFFFF] rounded-lg mb-2 p-4 overflow-y-auto shadow-md"
        ref={jobDetailRef}
      >
        <div className="flex justify-between items-center">
          <div className="flex-row">
            <p className="text-l font-semibold text-[#151416]">
              {jobInfo.companyName}
            </p>
            <p className="text-3xl font-bold mt-2 text-[#151416]">
              {jobInfo.title}
            </p>
          </div>
          <div className="flex-row">
            <a href={jobInfo.applicationMethod} target="_blank" className="flex items-center justify-center gap-2 w-40 py-1.5 rounded-md bg-primary-800 mb-2" rel="noreferrer">
              <p className="text-[#ffffff] text-sm">
                Apply Now
              </p>
            </a>
            <a href={`/talent/resume?id=${jobInfo.id}`} className="flex items-center justify-center gap-2 w-40 py-1.5 rounded-md border border-secondary-500 bg-white" rel="noreferrer">
              <p className="text-secondary-500 text-sm">
                Customize CV
              </p>
            </a>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex items-center gap-1 mt-2">
            <LiaIndustrySolid className="text-[#5B5565]" size={16} />
            <p className="text-sm text-[#5B5565]">{(jobInfo.industry && jobInfo.industry.name) ?? "Not Spcified"}</p>
          </div>
          <div className="flex items-center gap-1 mt-2">
            <MdOutlineWorkOutline className="text-[#5B5565]" size={16} />
            <p className="text-sm text-[#5B5565]">{(jobInfo.jobNature && jobInfo.jobNature.name) ?? "Not Spcified"}</p>
          </div>
          <div className="flex items-center gap-1 mt-2">
            <IoLocationOutline className="text-[#5B5565]" fontSize={14} />
            <p className="text-sm text-[#5B5565]">
              {jobInfo.location || 'Not specified'}
            </p>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex items-center gap-1 mt-2">
            <FaBusinessTime className="text-[#5B5565]" size={16} />
            <p className="text-sm text-[#5B5565]">{jobInfo.employmentMode ?? "Not Spcified"}</p>
          </div>
        </div>
        <div className='p-3 my-2 bg-[#FAF8FF] w-full rounded-md flex flex-col gap-y-3'>
          <div className='flex items-center gap-1 text-xs text-neutral-400'>
            <p>CU Job Reference No: {jobInfo.jobLinkJobId} | Closing Date: {jobInfo.closedAt ? format(new Date(jobInfo.closedAt), 'dd MMM yyyy HH:mm') : 'Not specified'}</p>
          </div>
        </div>
        <CustomTabs tabs={["Job Description", "Course Recommendation"]} nodes={{
          "Job Description": (
            <>
              {
                jobInfo.requirement && (
                  <JobDetailSectionWrapper title="Requirements">
                    {formatTextWithLineBreaks(jobInfo.requirement)}
                  </JobDetailSectionWrapper>
                )
              }
              {jobInfo.responsibility && (
                <JobDetailSectionWrapper title="Responsibilities">
                  {formatTextWithLineBreaks(jobInfo.responsibility)}
                </JobDetailSectionWrapper>
              )}
              {
                [jobInfo.bonus, jobInfo.mealAllowance, jobInfo.medical, jobInfo.annualLeave, jobInfo.commission, jobInfo.salaryMin, jobInfo.salaryMax]
                  .filter((item) => item && item !== '0')
                  .length > 0 &&
                <JobDetailSectionWrapper title="Remuneration Package">
                  <div className="flex gap-2 flex-wrap">
                    {
                      [
                        { name: 'Bonus', value: jobInfo.bonus },
                        { name: 'Meal Allowance', value: jobInfo.mealAllowance },
                        { name: 'Medical', value: jobInfo.medical },
                        { name: 'Annual Leave', value: jobInfo.annualLeave },
                        { name: 'Commission', value: jobInfo.commission }
                      ]
                        .filter((item) => item.value && item.value !== '0')
                        .map((item, index) => (
                          <TypographyCaption key={index} className="text-primary-800 py-1 px-4 rounded-full border-[1px] border-primary-800">
                            {item.name}: {isNaN(Number(item.value)) ? 'Include' : `${item.value} day${Number(item.value) > 1 ? 's' : ''}`}
                          </TypographyCaption>
                        ))
                    }
                    {
                      jobInfo.salaryMin && jobInfo.salaryMin !== "0" && jobInfo.salaryMax && jobInfo.salaryMax !== "0"
                        ? (
                          <TypographyCaption className="text-primary-800 py-1 px-4 rounded-full border-[1px] border-primary-800">
                            Salary: HK$ Monthly ${jobInfo.salaryMin} - ${jobInfo.salaryMax}
                          </TypographyCaption>
                        )
                        : null
                    }
                    {
                      jobInfo.salaryMin && jobInfo.salaryMin !== "0" && jobInfo.salaryMax === "0"
                        ? (
                          <TypographyCaption className="text-primary-800 py-1 px-4 rounded-full border-[1px] border-primary-800">
                            Salary: {jobInfo.salaryMin} HK$
                          </TypographyCaption>
                        )
                        : null
                    }
                    {
                      jobInfo.salaryMax && jobInfo.salaryMax !== "0" && jobInfo.salaryMin === "0"
                        ? (
                          <TypographyCaption className="text-primary-800 py-1 px-4 rounded-full border-[1px] border-primary-800">
                            Salary: {jobInfo.salaryMax} HK$
                          </TypographyCaption>
                        )
                        : null
                    }
                  </div>
                </JobDetailSectionWrapper>
              }
            </>
          ),
          "Course Recommendation": (
            <JobDetailSectionWrapper title='Recommended Courses'>
              <p className="text-sm text-neutral-700 font-normal mb-2">
              We recommend the following relevant courses based on this job opening.
              </p>
              <div className="flex gap-4 overflow-auto">
                {keepCourseList.map((course) => (
                  <CustomCard
                    key={course.courseId}
                    className="min-w-[320px]"
                    title={course.title}
                    description={course.instructors}
                    cardContent={
                      <div className="h-40 object-cover overflow-hidden">
                        <img src={course.image} alt="course" />
                      </div>
                    }
                    cardFooter={
                      <div className="w-full flex items-center justify-between gap-2">
                        <p className="text-sm text-neutral-600">{course.organization} · {course.duration}</p>
                        <Button
                          onClick={() => course.courseUrl && window.open(course.courseUrl, "_blank")}
                          className="bg-primary-500 hover:bg-primary-600 h-6 w-24">
                          Enroll
                        </Button>
                      </div>
                    }
                  />
                ))}
              </div>
            </JobDetailSectionWrapper>
          )
        }}
        />
      </div>
    </>
  );
}

export default JobDetailBox;
