import { getTalentProfile } from "@/utils/api/talent"
import { useQuery } from "@tanstack/react-query"

const useGetTalentProfile = () => {
  return useQuery({
    queryKey: ["talent-profile"],
    queryFn: getTalentProfile,
  })
}

export default useGetTalentProfile