import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import React from "react";

type CustomTooltipProps = {
    content: string;
    children: React.ReactNode;
}
  
const CustomTooltip = ({ children, content }: CustomTooltipProps) => {
  const isButtonChild = React.isValidElement(children) && children.type === Button;

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          {isButtonChild ? (
            children
          ) : (
            <Button
              variant='secondary'
              className="p-0 bg-transparent hover:bg-transparent text-inherit hover:text-inherit"
            >
              {children}
            </Button>
          )}
        </TooltipTrigger>
        <TooltipContent>
          <p>{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default CustomTooltip