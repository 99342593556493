import { createBrowserRouter } from "react-router-dom";
import JobPage from '@/pages/job/page';
import ResumeBuilder from '@/pages/resume/page';
import AdminHomePage from "./pages/admin/page";
import AdminInsightPage from "@/pages/admin/insight/page";
import AdminReportPage from "./pages/admin/report/page";
import AdminCallbackPage from "./pages/admin/callback/page";
import LoginPage from "./pages/login/page";
import ErrorPage from "./pages/error/page";
import JobDetailPage from "./pages/job/detail/page";

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        index: true,
        element: <JobPage />,
      },
      {
        path: "/talent/job/:id",
        element: <JobDetailPage />,
      },
      {
        path: "/talent/resume",
        element: <ResumeBuilder />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/admin",
        element: <AdminHomePage />,
      },
      {
        path: "/admin/callback",
        element: <AdminCallbackPage />,
      },
      {
        path: "/admin/insight",
        element: <AdminInsightPage />,
      },
      {
        path: "/admin/report",
        element: <AdminReportPage />,
      }
    ],
    errorElement: <ErrorPage errorCode={404} />,
  },
]);

export default router