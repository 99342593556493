import React from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { IoTrendingDown, IoTrendingUp } from 'react-icons/io5'

type AdminInsightCardProps = {
    title: string,
    content: string,
    category: string,
    performance?: number,
}

const AdminInsightCard: React.FC<AdminInsightCardProps> = (props) => {
  return (
    <Card className='bg-primary-50'>
      <CardHeader>
        <CardTitle className='text-sm text-neutral-600'>{props.title}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className='flex flex-col gap-2'>
          <h1 className='text-2xl text-neutral-900 font-semibold'>{props.content}</h1>
          <p className='text-sm text-neutral-600'>{props.category}</p>
          {!!props.performance && 
            (
              props.performance > 0 ?
                <p className='flex items-center gap-1 text-sm text-green-500 whitespace-nowrap'><IoTrendingUp />+{props.performance}% from last year</p>
                :
                <p className='flex items-center gap-1 text-sm text-red-500 whitespace-nowrap'><IoTrendingDown />{props.performance}% from last year</p>
            )
          }
        </div>
      </CardContent>
    </Card>
  )
}

export default AdminInsightCard