import { getUserInfo, login } from '@/utils/api/user'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import useAuthStore from '@/store/useAuthStore'

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const { login: loginUser } = useAuthStore();

  useEffect(() => {
    const onLogin = async (token: string) => {
      await login({ token })
      const userInfo = await getUserInfo()
      loginUser(userInfo)
      window.location.href = searchParams.get('redirect-uri') || '/'
    }

    onLogin(searchParams.get('token') || '')
  },[])

  return (
    <div>Loading...</div>
  )
}

export default LoginPage
