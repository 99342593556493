import { TypographyProps } from "@/components/customized-ui/typography/type"
import { cn } from "@/lib/utils"

export function P1({ children, className, ...rest }: TypographyProps<'p'>) {
  return (
    <p
      className={cn("text-[14px] font-normal", className)}
      {...rest}
    >
      {children}
    </p>
  )
}
  