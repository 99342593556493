import React from 'react'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'

export type CustomCardProps = {
    title: string;
    description?: string;
    cardContent?: React.ReactNode;
    cardFooter?: React.ReactNode;
} & React.ComponentProps<typeof Card>

const CustomCard: React.FC<CustomCardProps> = ({ title, description, cardContent, cardFooter, ...rest }) => {
  return (
    <Card {...rest}>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        {!!description && <CardDescription>{description}</CardDescription>}
      </CardHeader>
      <CardContent>
        {cardContent}
      </CardContent>
      {!!cardFooter && <CardFooter>
        {cardFooter}
      </CardFooter>}
    </Card>
  )
}

export default CustomCard