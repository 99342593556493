import { getInsight } from "@/utils/api/insight"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react";

const useGetInsight = () => {
  const [endedAt, setEndedAt] = useState<string>('');
  const query = useQuery({
    queryKey: [],
    queryFn: () => getInsight(endedAt),
    enabled: !!endedAt,
  })

  return {
    ...query,
    setEndedAt,
    endedAt,
  }
}

export default useGetInsight;