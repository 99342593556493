import { TypographyProps } from "@/components/customized-ui/typography/type"
import { cn } from "@/lib/utils"

export function TypographyH3({ children, className, ...rest }: TypographyProps<'h3'>) {
  return (
    <h3
      className={cn("scroll-m-20 text-2xl font-semibold tracking-tight", className)}
      {...rest}
    >
      {children}
    </h3>
  )
}