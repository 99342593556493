import React, { useCallback, useEffect, useState } from "react";
import JobInfoCard from "@/components/customized-ui/card/job-info-card";
import SearchInput from "@/components/Input/SearchInput";
import useGetJobList from "@/hooks/api/job/get-job-list";
import CustomSelect from "@/components/customized-ui/select/base";
import { SearchJobsResponse } from "@/types/api/response/job";
import useGetJobNatures from "@/hooks/api/dictionary/get-job-natures";
import { TypographyH4 } from "@/components/customized-ui/typography/h4";
import { DetailSkeleton, ListSkeleton } from "@/components/customized-ui/skeleton/job-page-skeleton";
import useGetJob from "@/hooks/api/job/get-job";
import Pagination from "@/components/customized-ui/pagination/base";
import useGetJobFunctions from "@/hooks/api/vector-search/get-job-functions";
import { Button } from "@/components/ui/button";
import { Controller, useForm } from "react-hook-form";
import { TypographySmall } from "@/components/customized-ui/typography/small";
import JobDetailBox from "@/components/customized-ui/card/job-detail-card";
import useGetIndustry from "@/hooks/api/dictionary/get-industry";
import { useSearchParams } from "react-router-dom";
import { MultiSearchCombobox } from "@/components/customized-ui/combobox/multi-search-box";
import { H1 } from "@/components/customized-ui/typography/h1";
import { TypographyP2 } from "@/components/customized-ui/typography/p2";
import { Zap } from "lucide-react";
import useDialogStore from "@/store/useDialogStore";

function App() {
  const { showDialog } = useDialogStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [jobList, setJobList] = useState<SearchJobsResponse>({ count: 0, objectList: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const { query: { data: jobDetail, isLoading: isLoadingJobDetail }, jobId, setJobId } = useGetJob();
  const { mutateAsync: searchJobList, isPending } = useGetJobList();

  const onOpenCalibrate = () => {
    showDialog('CALIBRATE', {
      title: 'Update Profile',
    })
  }
  
  const { control, getValues, reset } = useForm({
    defaultValues: {
      keyword: "",
      jobFunction: [],
      jobNature: [],
      industry: [],
      sortParam: "relevance",
    }
  });

  const onFetchJobDetail = async (jobId: string) => {
    setJobId(jobId);
  }

  const onRedirectToJobPage = async () => {
    if (getValues('keyword')) {
      searchParams.set("keyword", getValues('keyword') ?? searchParams.get('keyword'));
    } else {
      searchParams.delete("keyword");
    }
    if (getValues('jobNature').length > 0) {
      searchParams.set("job-nature", getValues('jobNature').join("|") ?? searchParams.get('job-nature'));
    } else {
      searchParams.delete("job-nature");
    }
    if (getValues('industry').length > 0) {
      searchParams.set("industry", getValues('industry').join("|") ?? searchParams.get('industry'));
    } else {
      searchParams.delete("industry");
    }
    if (searchParams.toString() === "") {
      window.history.replaceState({}, "", "/");
    } else {
      window.history.pushState({}, "", window.location.pathname + '?' + searchParams.toString());
    }

    await onFetchJobList();
  }

  const onFetchJobList = useCallback(async () => {
    const searchKeyword = searchParams.get('keyword');
    const searchJobFunction = getValues('jobFunction');
    const searchJobNatureJobLinkIdList: string[] = searchParams
      ?.get('job-nature')
      ?.split('|') 
      ?? [];
    const searchIndustryJobLinkIdList: string[] = searchParams
      ?.get('industry')
      ?.split('|')
      ?? [];

    setCurrentPage(1);
    reset({
      keyword: searchKeyword || '',
      jobFunction: searchJobFunction,
      jobNature: searchJobNatureJobLinkIdList as any[],
      industry: searchIndustryJobLinkIdList as any[],
      sortParam: "relevance",
    })
    const response = await searchJobList({
      page: 0,
      limit: 5,
      keyword: searchKeyword ?? "",
      jobFunctionList: searchJobFunction ? searchJobFunction : [],
      jobNatureList: searchJobNatureJobLinkIdList ?? [],
      industryList: searchIndustryJobLinkIdList ?? [],
      statusList: [],
      sortParam: "SIMILARITY",
    });
    if (response) {
      setJobList(response);
      if (response.objectList.length > 0) {
        setJobId(response.objectList[0].id)
      } else {
        setJobId("");
      }
    }
  }, [getValues, searchJobList, setJobId]);

  useEffect(() => {
    onFetchJobList();
  }, [onFetchJobList]);

  const onPageChange = async (pageNumber: number) => {
    const response = await searchJobList({
      page: pageNumber-1,
      limit: 5,
      jobFunctionList: getValues('jobFunction') ? getValues('jobFunction'): [],
      jobNatureList: getValues('jobNature') ? getValues('jobNature') : [],
      industryList: getValues('industry') ? getValues('industry') : [],
      statusList: [],
      sortParam: "SIMILARITY",
    });
    if (response) {
      setJobList(response);
    }
  }

  return (
    <div className="relative px-4 xl:px-0">
      <div className="max-w-6xl mx-auto my-6">
        <H1 className="font-semibold mb-2">
          Job 360
        </H1>
        <TypographyP2 className="text-neutral-700 mb-6">
          The jobs are recommended to you according to your <span className="underline cursor-pointer" onClick={() => {
            window.location.href = process.env.REACT_APP_PROFILE_URL || ''
          }}>CU Job Link</span> profile.
        </TypographyP2>
        <div className="flex gap-4">
          <Controller
            control={control}
            name='keyword'
            render={({ field }) => (
              <SearchInput
                placeholder="Search By Position..."
                value={field.value}
                onValueChange={field.onChange}
                onKeyDown={
                  (e) => {
                    if (e.key === 'Enter') {
                      onRedirectToJobPage();
                    }
                  }
                } />
            )}
          />
          <Button className="bg-primary-600 hover:bg-primary-700" onClick={onRedirectToJobPage}>Search</Button>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between gap-2 py-4">
          <div className="flex flex-col md:flex-row items-center gap-2">
            <Controller
              control={control}
              name='industry'
              render={({ field }) => (
                <MultiSearchCombobox
                  placeholder="Select Industry"
                  value={field.value}
                  onChange={field.onChange}
                  hook={useGetIndustry}
                  buttonStyle="border-primary-400 bg-primary-50 hover:bg-primary-50"
                  searchKey="jobLinkId"
                />
              )}
            />
            <Controller
              control={control}
              name='jobNature'
              render={({ field }) => (
                <MultiSearchCombobox
                  placeholder="Select Job Nature"
                  value={field.value}
                  onChange={field.onChange}
                  hook={useGetJobNatures}
                  buttonStyle="border-primary-400 bg-primary-50 hover:bg-primary-50"
                  searchKey="jobLinkId"
                />
              )}
            />
            <Controller
              control={control}
              name='jobFunction'
              render={({ field }) => (
                <MultiSearchCombobox
                  placeholder="Select Job Function"
                  value={field.value}
                  onChange={field.onChange}
                  searchKey="id"
                  hook={useGetJobFunctions}
                />
              )}
            />
            <a href="/" className="w-full text-sm text-neutral-500 whitespace-nowrap">
              Clear All Filters
            </a>
          </div>
          <div className="flex items-center gap-2">
            <TypographySmall className="text-neutral-500">Sort by Skill Relevance</TypographySmall>
            <Button className='border-secondary-800 flex items-center gap-2 text-secondary-800 hover:text-secondary-900' variant='outline' onClick={onOpenCalibrate}>
              <Zap className="w-4 h-4" />
              <p>Enhance match</p>
            </Button>
          </div>
        </div>
        {
          !isPending && jobList.objectList.length === 0 && (
            <div className='flex flex-col items-center justify-center gap-4 my-4 h-[calc(100vh_-_200px)] bg-white rounded-lg shadow-lg'>
              <TypographyH4>
                No result found
              </TypographyH4>
              <TypographySmall className='text-center text-neutral-500'>
                No results were found to match your search.
                <br/>
                Please consider adjusting the filters or verifying for any spelling errors.
              </TypographySmall>
            </div>
          )
        }
        {
          (isPending || jobList.objectList.length > 0) &&
          <div className="grid md:grid-cols-3 gap-4">
            <div className="">
              {
                isPending ? <ListSkeleton /> :
                  
                  <>
                    <div className="hidden md:flex flex-col gap-4 col-span-1">
                      {jobList.objectList.map((job) => (
                        <div key={job.id} onClick={() => onFetchJobDetail(job.id)}>
                          <JobInfoCard
                            selectJob={jobId}
                            jobInfo={job}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="flex md:hidden flex-col gap-4 col-span-1">
                      {jobList.objectList.map((job) => (
                        <div key={job.id} onClick={() => window.location.replace(`/talent/job/${job.id}`)}>
                          <JobInfoCard
                            selectJob={jobId}
                            jobInfo={job}
                          />
                        </div>
                      ))}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalItems={jobList.count}
                      itemsPerPage={5}
                      onPageChange={onPageChange}
                    />
                  </>
              }
            </div>
            <div className="hidden md:block col-span-2">
              {(isPending || isLoadingJobDetail) ? <DetailSkeleton/> :
                typeof jobDetail === 'object' && <JobDetailBox jobDetail={jobDetail} />}
            </div>
          </div>}
      </div>
    </div>
  );
}

export default App;
