import { CustomDialog } from './base'
import { TypographyP } from '@/components/customized-ui/typography/p'
import { Button } from '@/components/ui/button'
import useTalentCalibrate from '@/hooks/api/talent/talent-calibrate'
import { toast } from "@/components/ui/use-toast"
import { TypographyH2 } from '@/components/customized-ui/typography/h2'
import useDialogStore from '@/store/useDialogStore'

type CalibrateDialogProps = {
  open: boolean;
  isCompulsory?: boolean;
}

const SyncDialog = (props: CalibrateDialogProps) => {
  const { showDialog, hideDialog } = useDialogStore();
  const { mutateAsync: talentCalibrate } = useTalentCalibrate()

  const onSubmitCalibrate = async () => {
    showDialog('LOADING', {
      title: 'Syncing Profile',
      message: 'Please wait while we calibrate your profile.'
    })
    await talentCalibrate();
    hideDialog();
    toast({
      title: "Calibrate Successfully 🎉",
    })
    window.location.reload()
  }

  return (
    <CustomDialog open={props.open} onOpenChange={() => {
      hideDialog()
    }} disableCloseButton={props.isCompulsory}>
      <TypographyH2 className='font-mulish font-semibold text-center text-neutral-800'>
        Don't Lose Your CV Updates!
      </TypographyH2>
      <TypographyP className='text-neutral-800 font-mulish font-normal'>
        Before you leave, make sure to copy any changes from CV 360. If you refresh, your current updates will disappear.
      </TypographyP>
      <img src="/warning.png" alt="warning" className="w-1/2 mx-auto mb-4" />
      <form onSubmit={onSubmitCalibrate}>
        <div className='w-full flex justify-center gap-4'>
          <Button
            type='button'
            onClick={hideDialog}
            variant='ghost'
            className='text-primary-700'
          >
            Go Back
          </Button>
          <Button
            type='button'
            className='bg-primary-700 hover:bg-primary-800 text-white rounded-md px-6'
            onClick={onSubmitCalibrate}
          >
            Update My Profile Now
          </Button>
        </div>
      </form>
    </CustomDialog>

  )
}

export default SyncDialog