import { TalentInfo } from "@/types/common/talent";
import { Content } from "pdfmake/interfaces";
import { ContentCreator } from "@/lib/pdf/interface";

export default class HeaderContentCreator implements ContentCreator {
  private readonly talentInfo: TalentInfo;

  constructor(talentInfo: TalentInfo) {
    this.talentInfo = talentInfo;
  }

  async create(): Promise<Content> {
    const { firstName, lastName, email, phone, linkedinUri } = this.talentInfo;

    return [
      { text: `${firstName} ${lastName}`, fontSize: 18, bold: true, alignment: 'center' },
      {
        text: [email, phone, linkedinUri].filter(Boolean).join(' | '),
        fontSize: 11,
        alignment: 'center',
        margin: [0, 4, 0, 0]
      },
    ];
  }
}