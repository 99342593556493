"use client"

import { MonthPicker } from '@/components/customized-ui/date-picker/month-picker'
import DownloadReportDialog from '@/components/customized-ui/dialog/DownloadReportDialog'
import CustomSelect from '@/components/customized-ui/select/base'
import { H1 } from '@/components/customized-ui/typography/h1'
import { TypographyP2 } from '@/components/customized-ui/typography/p2'
import TextInput from '@/components/Input/TextInput'
import Section from '@/components/Section/Section'
import { useToast } from '@/components/ui/use-toast'
import { AdminGenerateReportRequest } from '@/types/api/request/admin'
import { UploadIcon } from '@radix-ui/react-icons'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

const AdminReportPage = () => {
  const { toast } = useToast();
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const { control, reset, getValues, watch } = useForm<AdminGenerateReportRequest>({
    defaultValues: {}
  })

  const handleDownloadReport = async () => {
    if (!getValues('file')) {
      toast({
        title: 'Please upload a file',
        description: 'A file is required to generate the report.',
      })
      return;
    }
    if (!getValues('type')) {
      toast({
        title: 'Please select a report type',
        description: 'A report type is required to generate the report.',
      })
      return;
    }
    if (!getValues('targetFaculty')) {
      toast({
        title: 'Please enter a target faculty',
        description: 'A target faculty is required to generate the report.',
      })
      return;
    }
    if (!getValues('sheetNumber')) {
      toast({
        title: 'Please select a sheet number',
        description: 'A sheet number is required to generate the report.',
      })
      return;
    }
    if (getValues('type') === 'employment_status' && !getValues('empStatusCol')) {
      toast({
        title: 'Please select an employment status column',
        description: 'An employment status column is required for this report type.',
      })
      return;
    }
    if (getValues('type') === 'monthly_salary' && !getValues('salaryCol')) {
      toast({
        title: 'Please select a salary column',
        description: 'A salary column is required for this report type.',
      })
      return;
    }
    if (!getValues('facultyCol')) {
      toast({
        title: 'Please select a faculty column',
        description: 'A faculty column is required to generate the report.',
      })
      return;
    }
    if (!getValues('progCol')) {
      toast({
        title: 'Please select a program column',
        description: 'A program column is required to generate the report.',
      })
      return;
    }
    if (!getValues('year')) {
      toast({
        title: 'Please select a year',
        description: 'A year is required to generate the report.',
      })
      return;
    }
    setOpenDownloadDialog(true)
  }

  return (
    <div className='max-w-6xl mx-auto py-4'>
      {openDownloadDialog && (
        <DownloadReportDialog
          reportContent={getValues()}
          onClose={() => setOpenDownloadDialog(false)}
          reportName={''}
          dialogProps={{
            title: 'Download Employment Report',
            message: 'Are you sure you want to download the employment report?',
            confirmText: 'Download',
            onConfirm: handleDownloadReport,
          }}
        />
      )}
      <div className='flex flex-col gap-4 w-fit mx-4'>
        <H1>Download GES Report</H1>
        <TypographyP2>View and retrieve GES data reports with ease.</TypographyP2>
        <Section
          title='Upload Dataset'
          message='Upload the data to analyze using the template.'
          isHideActionButton
        >
          <Controller
            control={control}
            name='file'
            render={({ field }) => (
              <div className="flex items-center">
                <input
                  type="file"
                  id="file-upload"
                  className="hidden"
                  accept=".xlsx, .xls"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        field.onChange(file);
                        setUploadedFileName(file.name)
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                />
                <label
                  htmlFor="file-upload"
                  className="cursor-pointer flex items-center justify-center gap-2 w-40 py-2 rounded-md text-sm text-white bg-primary-800 hover:bg-primary-900"
                >
                  <UploadIcon />
        Choose File
                </label>
                {field.value && (
                  <span className="text-sm text-neutral-800 underline ml-4">{uploadedFileName}</span>
                )}
              </div>
            )}
          />
        </Section>
        <Section
          title='Select Report Type'
          message='Choose a report to download'
          isHideActionButton
        >
          <Controller
            control={control}
            name='type'
            render={({ field: { value, onChange }}) => (
              <CustomSelect
                options={[
                  {id: 'monthly_salary', name: 'Monthly Salary'},
                  {id: 'employment_status', name: 'Employment Status'}
                ]}
                placeholder={value || 'Select Report Type'}
                onChange={(option) => {
                  onChange(option.id)
                }}
              />
            )}
          />
        </Section>
        <Section
          title='Filter Faculty & Department'
          message='Enter your target faculty for analysis'
          isHideActionButton
        >
          <Controller
            control={control}
            name='targetFaculty'
            render={({ field: { value, onChange }}) => (
              <TextInput
                value={value}
                onChange={onChange}
                placeholder='Enter your target faculty'
              />
            )}
          />
        </Section>
        <Section
          title='Select Report Year'
          message='Enter your target faculty for analysis'
          isHideActionButton
        >
          <Controller
            control={control}
            name='year'
            render={({ field: { value, onChange }}) => (
              <CustomSelect
                options={Array.from({length: new Date().getFullYear() - 1900}, (_, i) => ({
                  name: String(i + 1901),
                  id: String(i + 1901)
                })).reverse()}
                placeholder={value === null ? 'Present' : value || 'Select Year'}
                onChange={(value) => {
                  onChange(value.id)
                }}
                disabled={value === null}
              />
            )}
          />
        </Section>
        <Section
          title='Select Sheet Number'
          message='Select the target sheet number for your uploaded excel file'
          isHideActionButton
        >
          <Controller
            control={control}
            name='sheetNumber'
            render={({ field}) => (
              <TextInput
                {...field}
                min={1}
                placeholder='Enter Sheet Number'
                type='number'
              />
            )}
          />
        </Section>
        <Section
          title='Filter Columns'
          message='Select Excel columns for data comparison and visualization.'
          isHideActionButton
        >
          {
            watch('type') === 'employment_status' 
              ?
              <>
                <TypographyP2>
                  Empolyment Status Column
                </TypographyP2>
                <Controller
                  control={control}
                  name='empStatusCol'
                  render={({ field: { value, onChange }}) => (
                    <CustomSelect
                      options={ALPHABET.map((a) => ({id:a,name:a}))}
                      placeholder={value || 'Select Column'}
                      onChange={(option) => {
                        onChange(option.id)
                      }}
                    />
                  )}
                />
              </>
              : null
          }
          {
            watch('type') === 'monthly_salary' 
              ?
              <>
                <TypographyP2>
                  Salary Column
                </TypographyP2>
                <Controller
                  control={control}
                  name='salaryCol'
                  render={({ field: { value, onChange }}) => (
                    <CustomSelect
                      options={ALPHABET.map((a) => ({id:a,name:a}))}
                      placeholder={value || 'Select Column'}
                      onChange={(option) => {
                        onChange(option.id)
                      }}
                    />
                  )}
                />
              </>
              : null
          }
          <TypographyP2>Faculty Column</TypographyP2>
          <Controller
            control={control}
            name='facultyCol'
            render={({ field: { value, onChange }}) => (
              <CustomSelect
                options={ALPHABET.map((a) => ({id:a,name:a}))}
                placeholder={value || 'Select Column'}
                onChange={(option) => {
                  onChange(option.id)
                }}
              />
            )}
          />
          <TypographyP2>Program Column</TypographyP2>
          <Controller
            control={control}
            name='progCol'
            render={({ field: { value, onChange }}) => (
              <CustomSelect
                options={ALPHABET.map((a) => ({id:a,name:a}))}
                placeholder={value || 'Select Column'}
                onChange={(option) => {
                  onChange(option.id)
                }}
              />
            )}
          />
        </Section>
      </div>
      <button
        className="mt-6 flex items-center justify-center gap-2 w-40 py-2 rounded-md text-sm text-white bg-primary-800 hover:bg-primary-900"
        rel="noreferrer"
        onClick={handleDownloadReport}
      >
          Download as Excel
      </button>
    </div>
  )
}

export default AdminReportPage