import React from 'react'
import useDialogStore from '@/store/useDialogStore'
import ErrorDialog from './ErrorDialog'
import NotificationDialog from './NotificationDialog'
import LoadingDialog from './LoadingDialog'
import SyncDialog from './SyncDialog'

const GlobalDialog = () => {
  const { dialogProps, dialogType, isShow } = useDialogStore()

  const dialogMap = {
    ERROR: <ErrorDialog dialogProps={dialogProps} />,
    NOTIFICATION: <NotificationDialog dialogProps={dialogProps} />,
    LOADING: <LoadingDialog dialogProps={dialogProps} />,
    CALIBRATE: <SyncDialog open={true} />,
  }

  if (!isShow) return null

  return dialogMap[dialogType] || null
}

export default GlobalDialog