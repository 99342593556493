import { TalentExperience } from "@/types/common/talent";
import { Content } from "pdfmake/interfaces";
import { ContentCreator } from "@/lib/pdf/interface";
import sectionGenerator from "../components/section";

export default class ExperienceContentCreator implements ContentCreator {
  constructor(private readonly talentExperience: TalentExperience[]) {}

  async create(): Promise<Content> {
    return sectionGenerator({
      header: 'EXPERIENCES',
      sectionContent: this.talentExperience.map(({ title, startedAt, endedAt, companyName, description }) => ({
        title: companyName,
        startDate: startedAt,
        endDate: endedAt,
        relatedParty: title,
        description
      }))
    });
  }
}