export const authErrorContent = {
  title: 'We Missed a Step',
  message: 'Please revisit your CU Job Link profile and access CV / Job 360 from there.',
  imageUrl: '/upgrade.png',
  confirmText: 'Go To My Profile Now',
}

export const apiErrorContent = {
  title: 'Hang Tight!',
  message: `We're doing some maintenance and will be back shortly.`,
  imageUrl: '/maintenance.png',
  confirmText: 'Go Back to Profile',
}
  