import React from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import { RouterProvider } from "react-router-dom";
import router from '@/router';
import ApiProvider from '@/components/providers/api';
import AuthProvider from '@/components/providers/auth';
import Navbar from '@/components/layout/navbar';
import GlobalDialog from '@/components/customized-ui/dialog/GlobalDialog';
import { Toaster } from '@/components/ui/toaster';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ApiProvider>
    <AuthProvider>
      <GlobalDialog />
      <div className="fixed top-0 left-0 w-screen h-screen -z-10">
        <img className="w-full h-full object-cover" src="/bg.png" alt="background" />
      </div>
      <Navbar />
      <RouterProvider router={router} />
      <Toaster />
    </AuthProvider>
  </ApiProvider>
);