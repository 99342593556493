"use client"
import React from 'react'
import useDialogStore, { DialogProps } from '@/store/useDialogStore'
import { CustomDialog } from './base'
import { TypographyH2 } from '../typography/h2'

type ErrorDialogProps = {
    dialogProps: DialogProps,
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({ dialogProps }) => {
  const { hideDialog } = useDialogStore();
  const handleConfirm = async () => {
    if (!dialogProps?.onConfirm) return;
    await dialogProps.onConfirm();
    hideDialog();
  }
  return (
    <CustomDialog open={true} onOpenChange={hideDialog}>
      <div className="flex flex-col items-center gap-6 py-10">
        <TypographyH2 className='font-mulish font-semibold text-center text-neutral-800'>{dialogProps.title}</TypographyH2>
        <p className='text-base text-neutral-600 text-center font-mulish font-normal'>{dialogProps.message}</p>
        {dialogProps.imageUrl && <img src={dialogProps.imageUrl} alt="maintenance" className="mx-auto mt-6" />}
        <div className="flex items-center justify-center w-full">
          {
            dialogProps.confirmUrl ? 
              <a
                href={dialogProps.confirmUrl}
                type='button' 
                className='bg-primary-700 text-white w-full py-2 px-4 rounded-md text-center gap-2'
              >
                {dialogProps?.confirmText}
              </a>
              : 
              dialogProps?.onConfirm ?
                <button 
                  type='button' 
                  className='bg-primary-700 text-white w-full py-2 px-4 rounded-md text-center gap-2'
                  onClick={handleConfirm}
                >
                  {dialogProps?.confirmText}
                </button>
                : null
          }
        </div>
      </div>
    </CustomDialog>
  )
}

export default ErrorDialog