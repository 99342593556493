import {
  AlertDialog,
  AlertDialogContent,
} from "@/components/ui/alert-dialog"
import React from "react"
type CustomAlertDialogProps = {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    children: React.ReactNode;
    footerChildren?: React.ReactNode;
    disableCloseButton?: boolean;
}

export function CustomDialog(props: CustomAlertDialogProps) {
  return (
    <AlertDialog open={props.open} onOpenChange={props.onOpenChange}>
      <AlertDialogContent className="sm:max-w-xl px-10">
        {props.children}
      </AlertDialogContent>
    </AlertDialog>
  )
}
