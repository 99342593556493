import { getAttributes } from "@/utils/api/vector-search"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"

const useGetAttributes = () => {
  const [keyword, setKeyword] = useState<string>("");
  const query = useQuery({
    queryKey: ["get-attribute", { keyword: keyword || 'skill', limit: 10 }],
    queryFn: getAttributes,
    staleTime: 3000,
    enabled: !!keyword
  })

  return {
    ...query,
    keyword,
    setKeyword
  }
}

export default useGetAttributes;