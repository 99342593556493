import { useState, useCallback } from 'react';

interface IUseUndoRedo {
  state: string;
  setState: (newState: string) => void;
}

const useUndoRedo = ({ state, setState }: IUseUndoRedo) => {
  const [undoStack, setUndoStack] = useState<string[]>([]);
  const [redoStack, setRedoStack] = useState<string[]>([]);

  const undo = useCallback(() => {
    if (undoStack.length === 0) return;

    const previous = undoStack[undoStack.length - 1];
    const newUndoStack = undoStack.slice(0, -1);

    setUndoStack(newUndoStack);
    setRedoStack([state, ...redoStack]);
    setState(previous);
  }, [undoStack, state, redoStack, setState]);

  const redo = useCallback(() => {
    if (redoStack.length === 0) return;

    const next = redoStack[0];
    const newRedoStack = redoStack.slice(1);

    setUndoStack([...undoStack, state]);
    setRedoStack(newRedoStack);
    setState(next);
  }, [redoStack, undoStack, state, setState]);

  const set = useCallback((newState: string) => {
    if (newState === state) return;
    setState(newState);
    setUndoStack([...undoStack, state]);
    setRedoStack([]);
  }, [setState, undoStack, state]);

  return { state, set, undo, redo, undoStack, redoStack };
};

export default useUndoRedo;