import { GetAttributesRequest, GetJobFunctionsRequest } from "@/types/api/request/vector-search"
import { GetJobFunctionsResponse } from "@/types/api/response/vector-search"
import axiosInstance from "./config"

export const getJobFunctions = async (props: { queryKey: [string, GetJobFunctionsRequest] }): Promise<GetJobFunctionsResponse> => {
  return axiosInstance.get("/vector-search/job-function", { params: props.queryKey[1] })
}

export const getAttributes = async (props: { queryKey: [string, GetAttributesRequest] }): Promise<GetJobFunctionsResponse> => {
  return axiosInstance.get("/vector-search/attribute", { params: props.queryKey[1] })
}