import { format } from 'date-fns';
import { Content, ContentText } from 'pdfmake/interfaces';

interface SectionContent {
    title: string | null;
    startDate: string;
    endDate: string | null;
    relatedParty?: string;
    description?: string;
}

interface SectionGenerator {
    header: string;
    sectionContent: SectionContent[];
    isCombineRelatedParty?: boolean;
}

function formatDate(date: string | null): string {
  return date ? format(new Date(date), 'MMM yyyy') : 'Present';
}

function createContentText(text: string, style: string, options: Partial<ContentText> = {}): ContentText {
  return { text, style, ...options };
}

function createDescription(description?: string): Content {
  if (!description) return [];
  return {
    ul: description.split('\n').map(text => createContentText(text, 'subheader', { alignment: 'justify' })),
    marginTop: 2
  };
}

function sectionGenerator({ sectionContent, header, isCombineRelatedParty = true }: SectionGenerator): Content {
  if (sectionContent.length === 0) return [];

  const content: Content = [
    createContentText(header, 'header', { bold: true, alignment: 'left' }),
    { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 4, 0, 4] },
  ];

  sectionContent.forEach(({ title, startDate, endDate, relatedParty, description }, index) => {
    const titleText = createContentText(title || '', 'subheader', { bold: true });
    const relatedPartyText = createContentText(relatedParty || '', 'subheader', { bold: true });

    content.push(
      {
        columns: [
          { text: titleText, width: '75%' },
          { text: createContentText(startDate === endDate ? formatDate(startDate) : `${formatDate(startDate)} - ${formatDate(endDate)}`, 'subheader', { alignment: 'right' }), width: '25%' },
        ]
      },
      relatedPartyText,
      createDescription(description),
      { canvas: [], marginBottom: index !== sectionContent.length - 1 ? 4 : 0 }
    );
  });

  return content;
}

export default sectionGenerator;