import React from 'react'
import BaseTab from '@/components/customized-ui/tab/content/base-tab-content'

type ProfessionalSummaryTabProps = {
  summary: string;
}

const ProfessionalSummaryTab: React.FC<ProfessionalSummaryTabProps> = (props) => {
  return (
    <BaseTab title='Professional Summary'>
      <p className='text-sm text-neutral-600'>{props.summary}</p>
    </BaseTab>
  )
}

export default ProfessionalSummaryTab