import { Button } from "@/components/ui/button"
import { TypographyMuted } from "../typography/muted";
import { Label } from "@/components/ui/label";
import { Card } from "@/components/ui/card";
import { useRef, useState } from "react";
import { Bell, CopyIcon, ZapIcon, CheckIcon } from "lucide-react";
import { LoadingSpinner } from "../animate/loading-spinner";
import { TypographyList } from "../typography/list";
import { TbPencilMinus } from "react-icons/tb";
import { IoMdOptions } from "react-icons/io";
import { BiLeaf, BiMessage } from "react-icons/bi";
import { LuWind } from "react-icons/lu";
import { IoDocumentTextOutline } from "react-icons/io5";
import { PiBag } from "react-icons/pi";
import { cn } from "@/lib/utils";
import useTalentEnrichment from "@/hooks/api/talent/talent-enrichment";
import useTalentTemplate from "@/hooks/api/talent/talent-template";
import { TalentEnrichmentRequest } from "@/types/api/request/talent";
import CustomTooltip from "../tooltip/base";
import {CaretLeftIcon, CaretRightIcon} from "@radix-ui/react-icons";
import useUndoRedo from "@/hooks/common/useUndoRedo";
import styles from './enrichment-input.module.css';
import { TypographyCaption } from "../typography/caption";

type EnrichmentInputProps = {
    label?: string
    value: string
    onChange: (value: string) => void;
    jobFunctionId: string;
    enrichmentType: 'SUMMARY' | 'EXPERIENCE';
}

const EnrichmentInput = (props: EnrichmentInputProps) => {
  const [tab, setTab] = useState<"TEMPLATE"|"ENRICHMENT">("TEMPLATE")
  const [enrichType, setEnrichType] = useState<TalentEnrichmentRequest['prefix']>('FIX_GRAMMAR')
  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const { mutateAsync: onTalentTemplate, isPending: isPendingTemplate } = useTalentTemplate();
  const { mutateAsync: onTalentEnrichment } = useTalentEnrichment();
  const [isLoadingEnrichment, setIsLoadingEnrichment] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [enrichSuggest, setEnrichSuggest] = useState<string[]>([])
  const [appliedSuggestionIndex, setAppliedSuggestionIndex] = useState<number>();
  const {state: text, set: setText, undo, redo, undoStack, redoStack} = useUndoRedo({ state: props.value, setState: props.onChange });

  const onTriggerTalentTemplate = async () => {
    const res = await onTalentTemplate({ type: props.enrichmentType });
    setSuggestions(res);
    setAppliedSuggestionIndex(undefined);
  }

  const onTriggerTalentEnrichment = async () => {
    setIsLoadingEnrichment(true);
    const sentences = text.split('\n');
    const res = await Promise.all(sentences.map(sentence => onTalentEnrichment({ 
      action: props.enrichmentType === 'EXPERIENCE' ? 'ENRICH_EXPERIENCE' : 'ENRICH_SUMMARY',
      temperature: 0,
      text: sentence,
      prefix: enrichType
    })));
    setEnrichSuggest(res)
    setAppliedSuggestionIndex(undefined);
    setIsLoadingEnrichment(false);
  }

  const onSuggestionClick = (suggestion: string, index: number) => {
    navigator.clipboard.writeText(suggestion);
    setAppliedSuggestionIndex(index);
  }
    
  return (
    <div>
      {props.label ? <Label className="text-base font-bold">{props.label}</Label> : null}
      <div className="grid grid-cols-2 gap-2 h-80 mb-16">
        <div className="flex flex-col">
          <div className="flex gap-2 items-center bg-[#FEF1ED4D] p-2 shadow-sm shadow-secondary-600 rounded-t-md text-secondary-800">
            <TypographyMuted>Edit content with AI :</TypographyMuted>
            <CustomTooltip
              content='Enhance content'
            >
              <IoMdOptions
                size={32}
                className={cn("border-[2px] border-secondary-500 rounded-full p-1 cursor-pointer", tab === 'ENRICHMENT' ? 'bg-secondary-600 text-white shadow-md shadow-secondary-600' : '')}
                onClick={() => setTab('ENRICHMENT')}
              />
            </CustomTooltip>
            <CustomTooltip
              content='Add popular phrases'
            >
              <TbPencilMinus
                size={32}
                className={cn("border-[2px] border-secondary-500 rounded-full p-1 cursor-pointer", tab === 'TEMPLATE' ? 'bg-secondary-600 text-white shadow-md shadow-secondary-600' : '')}
                onClick={() => setTab('TEMPLATE')}
              />
            </CustomTooltip>
            <div className={'flex gap-2 ml-auto'}>
              <CustomTooltip
                content='Undo'
              >
                <Button
                  variant='ghost'
                  onClick={undo}
                  disabled={!undoStack.length}
                  size='icon'
                  className={cn("rounded-full w-8 h-8 hover:bg-secondary-800 hover:text-white", undoStack.length > 0 ? "bg-secondary-600 text-white" : "text-secondary-600")}>
                  <CaretLeftIcon className='scale-150'/>
                </Button>
              </CustomTooltip>
              <CustomTooltip
                content='Redo'
              >
                <Button
                  variant='ghost'
                  onClick={redo}
                  size='icon'
                  disabled={!redoStack.length}
                  className={cn("rounded-full w-8 h-8 hover:bg-secondary-800 hover:text-white", redoStack.length > 0 ? "bg-secondary-600 text-white" : "text-secondary-600")}
                >
                  <CaretRightIcon className='scale-150'/>
                </Button>
              </CustomTooltip>
            </div>
          </div>
          <div className="relative flex flex-col items-end bg-white shadow-sm shadow-secondary-500 rounded-b-md pl-1">
            <div
              className={cn(
                styles.textArea,
                "w-full overflow-auto min-h-[260px] max-h-[260px] px-5 pt-1 mb-16 rounded-t-none border-t-0 text-neutral-800"
              )}
              ref={el => {
                if (el) {
                  el.innerHTML = text?.split('\n').map(paragraph => `<div>${paragraph}</div>`).join('');
                }
                descriptionRef.current = el;
              }}
              contentEditable
              onBlur={() => {
                const innerText = descriptionRef.current?.innerText.replace(/\n$/, '');
                setText(innerText || '');
              }}
            />
            <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 w-[calc(100%-2rem)] h-10 bg-secondary-50 text-secondary-800 flex items-center gap-2 p-3">
              <Bell size={16} className="text-secondary-800"/>
              <TypographyCaption>
                Changes aren't saved — transfer updates to CU Job Link profile.
              </TypographyCaption>
            </div>
          </div>
        </div>
        {tab === 'TEMPLATE' ? <Card className="relative overflow-auto p-4 flex flex-col gap-4 rounded-md bg-secondary-50">
          <div className="absolute bottom-4 left-0 w-full h-2 text-center text-neutral-600">
            <TypographyCaption>
              AI can make mistakes. Check important info. 
            </TypographyCaption>
          </div>
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <TbPencilMinus size={32} className="text-secondary-800 border-[2px] border-secondary-500 rounded-full p-1"/>
              <TypographyMuted className="text-secondary-800 font-semibold">Add popular phrases</TypographyMuted>
            </div>
            <Button type='button' className="bg-primary-800 hover:bg-primary-900 flex items-center ml-auto gap-2" onClick={onTriggerTalentTemplate}>
              <ZapIcon size={16} />
              Generate
            </Button>
          </div>
          {
            isPendingTemplate &&
            <div className="flex flex-col gap-4 justify-center items-center text-secondary-800">
              <TypographyMuted>
                Generating suggestions...
              </TypographyMuted>
              <LoadingSpinner />
            </div>
          }
          {
            (!isPendingTemplate && suggestions.length === 0) &&
            <TypographyMuted className="text-secondary-800 text-center">Click on the button above to generate suggestions!</TypographyMuted>
          }
          <TypographyList className="h-[calc(100%-75px)] overflow-auto">
            {
              (!isPendingTemplate && suggestions.length > 0) &&
            Object.values(suggestions).map((suggestion, index) => (
              <div key={index} className="bg-white rounded-md grid grid-cols-12 p-4">
                <CustomTooltip
                  content={appliedSuggestionIndex === index ? 'Copied' : 'Copy to clipboard'}
                >
                  <Button
                    type='button'
                    onClick={() => {
                      if (appliedSuggestionIndex !== index) {
                        onSuggestionClick(suggestion, index);
                      }
                    }}
                    className={cn("col-span-1 bg-secondary-600 hover:bg-secondary-800 h-6 w-6 p-0", appliedSuggestionIndex === index ? 'bg-secondary-100 hover:bg-secondary-100 cursor-text' : '')}
                  >
                    {appliedSuggestionIndex === index ? <CheckIcon size={16} /> : <CopyIcon size={16} />}
                  </Button>
                </CustomTooltip>
                <TypographyMuted className="col-span-11 text-neutral-500">{suggestion}</TypographyMuted>
              </div>
            ))
            }
          </TypographyList>
        </Card> : null}
        {tab === 'ENRICHMENT' ? <Card className="relative overflow-auto p-4 flex flex-col gap-4 rounded-md bg-secondary-50">
          <div className="absolute bottom-4 left-0 w-full h-2 text-center text-neutral-600">
            <TypographyCaption>
              AI can make mistakes. Check important info. 
            </TypographyCaption>
          </div>
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <IoMdOptions size={32} className="text-secondary-800 border-[2px] border-secondary-500 rounded-full p-1"/>
              <TypographyMuted className="text-secondary-800 font-semibold">Enhance content</TypographyMuted>
            </div>
            <Button type='button' className="bg-primary-800 hover:bg-primary-900 flex items-center ml-auto gap-2" onClick={onTriggerTalentEnrichment} disabled={!text}>
              <ZapIcon size={16} />
              Generate
            </Button>
          </div>
          <div className="flex gap-2 flex-wrap">
            {
              [
                { label: 'Fix grammar', value: 'FIX_GRAMMAR', icon: <BiLeaf /> },
                { label: 'Simpler words', value: 'REWRITE', icon: <LuWind /> },
                { label: 'Paraphrase', value: 'PARAPHRASE', icon: <IoDocumentTextOutline /> },
                { label: 'Sound formal', value: 'WRITE_FORMALLY', icon: <PiBag /> },
                { label: 'Sound neutral', value: 'WRITE_NEUTRALLY', icon: <BiMessage /> },
              ].map(({ label, value, icon }: any) => (
                <button
                  key={value}
                  type='button'
                  className={cn(
                    "flex gap-2 items-center text-sm px-2 py-1 rounded-md border-[2px] border-secondary-200",
                    enrichType === value ? "border-secondary-800 shadow-sm shadow-secondary-600 bg-white text-secondary-800" : "bg-white text-neutral-600"
                  )}
                  onClick={() => setEnrichType(value)}
                >
                  {icon}
                  {label}
                </button>
              ))
            }
          </div>
          {
            isLoadingEnrichment &&
            <div className="flex flex-col gap-4 justify-center items-center text-secondary-800">
              <TypographyMuted>
                Generating suggestions...
              </TypographyMuted>
              <LoadingSpinner />
            </div>
          }
          <TypographyList className="h-[calc(100%-160px)] overflow-auto">
            {
              (!isLoadingEnrichment && enrichSuggest && enrichSuggest.length > 0) &&
              enrichSuggest.map((suggestion, index) => (
                <div key={index} className="bg-white rounded-md grid grid-cols-12 p-4">
                  <CustomTooltip
                    content={appliedSuggestionIndex === index ? 'Copied' : 'Copy to clipboard'}
                  >
                    <Button
                      type='button'
                      onClick={() => {
                        if (appliedSuggestionIndex !== index) {
                          onSuggestionClick(suggestion, index);
                        }
                      }} className={cn("col-span-1 bg-secondary-600 hover:bg-secondary-800 h-6 w-6 p-0", appliedSuggestionIndex === index ? 'bg-secondary-100 hover:bg-secondary-100 cursor-text' : '')}>
                      {appliedSuggestionIndex === index ? <CheckIcon size={16} /> : <CopyIcon size={16} />}
                    </Button>
                  </CustomTooltip>
                  <TypographyMuted className="col-span-11 text-neutral-500">{suggestion}</TypographyMuted>
                </div>
              ))
            }
          </TypographyList>
        </Card> : null}
      </div>
    </div>
  )
}

export default EnrichmentInput