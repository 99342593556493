import { getTalentSearch } from "@/utils/api/admin";
import { useQuery } from "@tanstack/react-query"
import { useState } from "react";

const useTalentSearch = () => {
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(0);
  const mutate = useQuery({
    queryKey: ['talent-search', keyword, page],
    queryFn: () => getTalentSearch({ page, limit: 10, keyword }),
  })

  return {
    ...mutate,
    keyword,
    setKeyword,
    page,
    setPage,
  }
}

export default useTalentSearch;