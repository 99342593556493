import React from 'react'
import BaseTab from '@/components/customized-ui/tab/content/base-tab-content'
import { TalentQualification } from '@/types/common/talent'
import { TypographyP2 } from '../../typography/p2';

type EducationTabProps = {
  educations: TalentQualification[];
}

const EducationTab: React.FC<EducationTabProps> = (props) => {
  return (
    <BaseTab title='Education'>
      {
        props.educations.map((education, index) => (
          <div className='flex flex-col'>
            <TypographyP2 className='text-[#656565] font-semibold'>{education.major ?? ''}, {education.institution}</TypographyP2>
            <TypographyP2 className='text-[#656565] font-semibold'>{education.startedAt} - {education.endedAt ?? 'Present'}</TypographyP2>
          </div>
        ))
      }
    </BaseTab>
  )
}

export default EducationTab