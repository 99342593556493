import React from 'react'
import { cn } from '@/lib/utils'

interface SubheadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode
}

const Subheading = React.forwardRef<HTMLHeadingElement, SubheadingProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <h2
        className={cn(
          "text-xl leading-none tracking-tight",
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </h2>
    )
  }
)

Subheading.displayName = "Subheading"

export { Subheading }
