import { getUserInfo } from '@/utils/api/user';
import React, { PropsWithChildren } from 'react'

const AuthProvider = (props: PropsWithChildren) => {

  React.useEffect(() => {
    const isAdmin = window.location.pathname.includes('admin');
    const checkAuth = async () => {
      const userInfo = await getUserInfo();
      if (window.location.pathname !== '/login' && window.location.pathname !== '/admin/callback') {
        if (isAdmin && userInfo.role !== 'ADMIN') {
          throw new Error('Unauthorized access: Admin privileges required.');
        }
        if (!isAdmin && userInfo.role !== 'STUDENT') {
          throw new Error('Unauthorized access: Student privileges required.');
        }
      }
    };

    checkAuth();
  }, []);

  return (
    <div>
      {props.children}
    </div>
  )
}

export default AuthProvider