import { getJobFunctions } from "@/utils/api/vector-search"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"

const useGetJobFunctions = () => {
  const [keyword, setKeyword] = useState<string>("")
  const query = useQuery({
    queryKey: ["job-functions", { keyword: keyword, limit: 10 }],
    queryFn: getJobFunctions,
    staleTime: 3000,

  })

  return {
    ...query,
    keyword,
    setKeyword
  }
}

export default useGetJobFunctions;