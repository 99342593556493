"use client"

import * as React from "react"
import { CaretSortIcon } from "@radix-ui/react-icons"
import { IoCheckmarkSharp } from "react-icons/io5"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Dictionary } from "@/types/common/dictionary"
import { UseQueryResult } from "@tanstack/react-query"
import { useDebouncedCallback } from 'use-debounce';
import { TypographySmall } from "../typography/small"

type MultiComboboxInputProps = {
  value: string[];
  placeholder?: string;
  searchKey: keyof Dictionary;
  onChange: (value: string[]) => void;
  hook: () => UseQueryResult<Dictionary[], Error> & { keyword: string, setKeyword: (value: string) => void }
  buttonStyle?: string;
}

export function MultiSearchCombobox({
  value,
  placeholder = "Select Option",
  searchKey,
  onChange,
  hook,
  buttonStyle
}: MultiComboboxInputProps) {
  const [open, setOpen] = React.useState(false)
  const { data: options = [], setKeyword, keyword } = hook();
  const [selectedOptions, setSelectedOptions] = React.useState<Record<string, string>>({});

  React.useEffect(() => {
    setKeyword("")
  }, [open, setKeyword])

  const onDebouncedSetKeyword = useDebouncedCallback(
    (value) => setKeyword(value),
    500
  );

  const onModifyItem = (selectedValue: string) => {
    const newValue = value.includes(selectedValue)
      ? value.filter(v => v !== selectedValue)
      : [...value, selectedValue];
    onChange(newValue);
    setSelectedOptions(prev => ({
      ...prev,
      [selectedValue]: options.find(v => v[searchKey] === selectedValue)?.name || "",
    }));
  }

  const getDisplayText = () => {
    if (value.length === 0) return placeholder;
    if (value.length === 1) {
      const selectedOption = options.find(option => option[searchKey] === value[0]);
      return selectedOptions[value[0]] || selectedOption?.name || placeholder;
    }
    return `${value.length} options selected`;
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-full", buttonStyle)}
        >
          <TypographySmall className="text-ellipsis max-w-[140px] overflow-hidden whitespace-nowrap">
            {getDisplayText()}
          </TypographySmall>
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder={`Search ${placeholder.toLowerCase()}...`}
            onValueChange={onDebouncedSetKeyword}
            className="h-9 focus:ring-0 focus:ring-inset"
          />
          <CommandEmpty>No option found.</CommandEmpty>
          <CommandGroup className="max-h-80 overflow-auto">
            {options
              .filter((option, index, self) => 
                self.findIndex(o => o.name === option.name) === index &&
                option.name.toLowerCase().includes(keyword.toLowerCase())
              )
              .map((option) => (
                <CommandItem
                  key={option.id}
                  value={option[searchKey] as string}
                  onSelect={onModifyItem}
                  className={cn(value.includes(option[searchKey] as string) ? "bg-primary-50" : "bg-white")}
                >
                  <TypographySmall className="w-11/12">{option.name}</TypographySmall>
                  <IoCheckmarkSharp
                    size={14}
                    className={cn(
                      "absolute right-2",
                      value.includes(option[searchKey] as string) ? "opacity-100" : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
