import { TalentExtraCurricularActivity } from "@/types/common/talent";
import { Content } from "pdfmake/interfaces";
import { ContentCreator } from "@/lib/pdf/interface";
import sectionGenerator from "../components/section";

export default class ExtraCurricularActivityContentCreator implements ContentCreator {
  constructor(private readonly activities: TalentExtraCurricularActivity[]) {}

  async create(): Promise<Content> {
    return sectionGenerator({
      header: 'EXTRA-CURRICULAR ACTIVITIES',
      sectionContent: this.activities.map(({ title, startedAt, endedAt, institution, description }) => ({
        title: institution,
        startDate: startedAt,
        endDate: endedAt,
        relatedParty: title,
        description
      }))
    });
  }
}