"use client";

import * as React from "react";
import { X } from "lucide-react";

import { Badge } from "@/components/ui/badge";
import {
  Command,
  CommandGroup,
  CommandItem,
} from "@/components/ui/command";
import { Command as CommandPrimitive } from "cmdk";
import { TalentSkill } from "@/types/common/talent";
import useGetAttributes from "@/hooks/api/vector-search/get-attributes";
import { useDebouncedCallback } from "use-debounce";

type SkillMultiSelectProps = {
    skillType: string;
    talentSkills: TalentSkill[];
    setTalentSkills: (talentSkills: TalentSkill[]) => void;
}

export function SkillMultiSelect({ skillType, talentSkills, setTalentSkills }: SkillMultiSelectProps) {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<string>("");
  const { data: attributeList, setKeyword } = useGetAttributes();

  const handleSelect = React.useCallback((talentSkill: TalentSkill) => {
    if (!talentSkills.some(skill => skill.name === talentSkill.name)) {
      setTalentSkills([...talentSkills, talentSkill]);
    }
    setInputValue("");
    setKeyword("");
  }, [setKeyword, setTalentSkills, talentSkills]);

  const handleUnselect = React.useCallback((talentSkill: TalentSkill) => {
    setTalentSkills(talentSkills.filter(s => s.name !== talentSkill.name));
  }, [setTalentSkills, talentSkills]);

  const handleSearchAttribute = useDebouncedCallback(
    (value) => {
      setKeyword(value);
    },
    500
  );

  const handleSearch = React.useCallback((value: string) => {
    setInputValue(value);
    handleSearchAttribute(value);
  }, [handleSearchAttribute]);

  const filteredAttributeList = React.useMemo(() => {
    return attributeList?.filter(attr => !talentSkills.some(skill => skill.name === attr.name)) || [];
  }, [attributeList, talentSkills]);

  return (
    <Command className="overflow-visible bg-transparent" shouldFilter={false}>
      <div
        className="group bg-white border border-input px-3 py-2 text-sm ring-offset-background rounded-md focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2"
      >
        <div className="flex gap-1 flex-wrap">
          {talentSkills.map((talentSkill, index) => (
            <Badge key={index} variant="secondary" className="bg-secondary-50">
              {talentSkill.score ? (
                <div className="flex items-center gap-x-2">
                  <span>{talentSkill.name} ({talentSkill.score})</span>
                </div>
              ) : talentSkill.name}
              <button
                className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleUnselect(talentSkill);
                  }
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onClick={() => handleUnselect(talentSkill)}
              >
                <X className="h-3 w-3 text-muted-foreground hover:text-foreground text-secondary-800" />
              </button>
            </Badge>
          ))}
          <CommandPrimitive.Input
            value={inputValue}
            onValueChange={handleSearch}
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            placeholder="Select frameworks..."
            className="ml-2 bg-transparent outline-none placeholder:text-muted-foreground flex-1"
          />
        </div>
      </div>
      <div className="relative mt-2">
        {open && filteredAttributeList.length > 0 && (
          <div className="absolute w-full z-10 top-0 rounded-md border text-popover-foreground shadow-md outline-none animate-in">
            <CommandGroup className="h-full overflow-auto">
              {!talentSkills.some(skill => skill.name.toLowerCase() === inputValue.toLowerCase()) && (
                <CommandItem
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onSelect={() => {
                    handleSelect({
                      name: inputValue,
                      id: '',
                      score: null,
                      type: skillType
                    });
                  }}
                  className="cursor-pointer bg-white"
                >
                  <div className="flex items-center gap-x-2">
                    <Badge>Manual</Badge>
                    <span>{inputValue}</span>
                  </div>
                </CommandItem>
              )}
              {filteredAttributeList.map((talentSkill, index) => (
                <CommandItem
                  key={index}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onSelect={() => {
                    handleSelect({
                      name: talentSkill.name,
                      id: talentSkill.id,
                      score: null,
                      type: skillType
                    });
                  }}
                  className="cursor-pointer bg-white"
                >
                  {talentSkill.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </div>
        )}
      </div>
    </Command>
  );
}