import * as React from "react";
import { ChevronLeft, ChevronRight, Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { Button, buttonVariants } from "@/components/ui/button";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { isValid } from "date-fns";
import { Label } from "@/components/ui/label";

type Month = {
    number: number;
    name: string;
};

const MONTHS: Month[][] = [
  [
    { number: 0, name: "Jan" },
    { number: 1, name: "Feb" },
    { number: 2, name: "Mar" },
    { number: 3, name: "Apr" },
  ],
  [
    { number: 4, name: "May" },
    { number: 5, name: "Jun" },
    { number: 6, name: "Jul" },
    { number: 7, name: "Aug" },
  ],
  [
    { number: 8, name: "Sep" },
    { number: 9, name: "Oct" },
    { number: 10, name: "Nov" },
    { number: 11, name: "Dec" },
  ],
];

type MonthCalProps = {
    isEndDate?: boolean;
    selectedDate: Date | null;
    onMonthSelect: (date: Date | null) => Promise<void>;
    onYearForward?: () => void;
    onYearBackward?: () => void;
    callbacks?: {
        yearLabel?: (year: number) => string;
        monthLabel?: (month: Month) => string;
    };
    variant?: {
        calendar?: {
            main?: ButtonVariant;
            selected?: ButtonVariant;
        };
        chevrons?: ButtonVariant;
    };
    minDate?: Date;
    disabledDates?: Date[];
    isLoading?: boolean;
    label?: string;
};

type ButtonVariant = "default" | "outline" | "ghost" | "link" | "destructive" | "secondary" | null | undefined;

function MonthPicker({
  onMonthSelect,
  selectedDate: selectedMonth,
  minDate,
  disabledDates,
  callbacks,
  onYearBackward,
  onYearForward,
  variant,
  className,
  isEndDate,
  label,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & MonthCalProps) {
  const [date, setDate] = React.useState<Date | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  React.useEffect(() => {
    setDate(selectedMonth || null);
  }, [selectedMonth]);

  return (
    <div className="flex flex-col gap-2">
      {label && <Label className="text-neutral-700">{label}</Label>}
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" className={cn("justify-start text-left font-normal", !date && "text-muted-foreground")} disabled={isLoading}>
            {
              isLoading ? 
                <Loader2 className="mr-2 h-4 w-4 animate-spin" /> :
                <CalendarIcon className="mr-2 h-4 w-4" />
            }
            {
              isLoading ?
                <span className="ml-2">Loading...</span> :
                selectedMonth && isValid(selectedMonth) ? format(selectedMonth, "MMM yyyy") : <span>{isEndDate ? 'Now' : 'Pick a month'}</span>
            }
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          {!isLoading && (
            <div className={cn("min-w-[200px] w-[280px] p-3", className)} {...props}>
              <div className="flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0">
                <div className="space-y-4 w-full">
                  <MonthCal
                    onMonthSelect={async (newDate) => {
                      setDate(newDate);
                      setIsLoading(true);
                      if (onMonthSelect) await onMonthSelect(newDate);
                      setIsLoading(false);
                    }}
                    callbacks={callbacks}
                    selectedDate={date}
                    onYearBackward={onYearBackward}
                    onYearForward={onYearForward}
                    variant={variant}
                    minDate={minDate}
                    disabledDates={disabledDates}
                    isEndDate={isEndDate}
                  />
                </div>
              </div>
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
}

function MonthCal({ selectedDate: selectedMonth, onMonthSelect, callbacks, variant, minDate, disabledDates, onYearBackward, onYearForward, isEndDate }: MonthCalProps) {
  const [year, setYear] = React.useState<number>(selectedMonth?.getFullYear() ?? new Date().getFullYear());
  const [month, setMonth] = React.useState<number>(selectedMonth?.getMonth() ?? new Date().getMonth());
  const [menuYear, setMenuYear] = React.useState<number>(year);

  const disabledDatesMapped = disabledDates?.map((d) => {
    return { year: d.getFullYear(), month: d.getMonth() };
  });

  return (
    <>
      <div className="flex justify-center pt-1 relative items-center">
        <div className="text-sm font-medium">{callbacks?.yearLabel ? callbacks?.yearLabel(menuYear) : menuYear}</div>
        <div className="space-x-1 flex items-center">
          <button
            onClick={(e) => {
              e.preventDefault();
              setMenuYear(menuYear - 1);
              if (onYearBackward) onYearBackward();
            }}
            className={cn(buttonVariants({ variant: variant?.chevrons ?? "outline" }), "inline-flex items-center justify-center h-7 w-7 p-0 absolute left-1")}
          >
            <ChevronLeft className="opacity-50 h-4 w-4" />
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setMenuYear(menuYear + 1);
              if (onYearForward) onYearForward();
            }}
            className={cn(buttonVariants({ variant: variant?.chevrons ?? "outline" }), "inline-flex items-center justify-center h-7 w-7 p-0 absolute right-1")}
          >
            <ChevronRight className="opacity-50 h-4 w-4" />
          </button>
        </div>
      </div>
      <table className="w-full border-collapse space-y-1">
        <tbody>
          {MONTHS.map((monthRow, a) => {
            return (
              <tr key={"row-" + a} className="flex w-full mt-2">
                {monthRow.map((m) => {
                  return (
                    <td
                      key={m.number}
                      className="h-10 w-1/4 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20"
                    >
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setMonth(m.number);
                          setYear(menuYear);
                          if (onMonthSelect) onMonthSelect(new Date(menuYear, m.number));
                        }}
                        disabled={
                          (minDate ? menuYear < minDate?.getFullYear() || (menuYear === minDate?.getFullYear() && m.number < minDate.getMonth()) : false) ||
                          (disabledDatesMapped ? disabledDatesMapped?.some((d) => d.year === menuYear && d.month === m.number) : false)
                        }
                        className={cn(
                          buttonVariants({ variant: month == m.number && menuYear === year ? variant?.calendar?.selected ?? "default" : variant?.calendar?.main ?? "ghost" }),
                          "h-full w-full p-0 font-normal aria-selected:opacity-100"
                        )}
                      >
                        {callbacks?.monthLabel ? callbacks.monthLabel(m) : m.name}
                      </button>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {
        isEndDate &&
        <Button
          variant={selectedMonth === null ? 'default' : 'outline'}
          className="w-full mt-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setMonth(new Date().getMonth());
            setYear(new Date().getFullYear());
            if (onMonthSelect) onMonthSelect(null);
          }}>Present</Button>}
    </>
  );
}

MonthPicker.displayName = "MonthPicker";

export { MonthPicker };