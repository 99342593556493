import React, { useEffect } from 'react'
import useAdminLogin from '@/hooks/api/admin/login'
import { useSearchParams } from 'react-router-dom';

const AdminCallbackPage = () => {
  const [searchParams,] = useSearchParams();
  const { mutateAsync: adminLogin } = useAdminLogin();

  useEffect(() => {
    const onAdminLogin = async () => {
      const adminToken = searchParams.get('token');
      if (adminToken) {
        await adminLogin({ token: adminToken });
      }
      window.location.href = '/admin';
    }
    onAdminLogin();
  }, [])

  return (
    <div>Loading</div>
  )
}

export default AdminCallbackPage