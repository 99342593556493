import { IoLocationOutline } from "react-icons/io5";
import { LiaIndustrySolid } from "react-icons/lia";
import { MdOutlineWorkOutline } from "react-icons/md";
import { Job } from "@/types/common/job";

type JobInfoBoxProps = {
  jobInfo: Job;
  selectJob?: string;
  isBookmarked?: boolean;
};

const JobInfoCard = ({ jobInfo, selectJob }: JobInfoBoxProps) => {
  const { id, title, companyName, requirement, location, jobNature, industry } =
    jobInfo;

  return (
    <div
      className={`p-4 rounded-lg shadow-lg flex flex-col gap-y-1 hover:shadow-xl cursor-pointer ${
        selectJob === id
          ? "border-2 border-[#F5997D] bg-[#FFF8F6]"
          : "border-2 border-transparent bg-[#FFF]"
      }`}
    >
      <div className="flex justify-between items-center">
        <p className="text-sm text-[#151416]">{companyName}</p>
      </div>
      <p className="text-base font-semibold text-[#151416]">{title}</p>
      <div className="flex items-center gap-1">
        <LiaIndustrySolid className="text-[#5B5565]" size={16} />
        <p className="text-sm text-[#5B5565]">{(industry && industry.name) ?? "Not Specified"}</p>
      </div>
      <div className="flex items-center gap-1">
        <MdOutlineWorkOutline className="text-[#5B5565]" size={16} />
        <p className="text-sm text-[#5B5565]">{(jobNature && jobNature.name) ?? "Not Spcified"}</p>
      </div>
      <div className="flex items-center gap-1">
        <IoLocationOutline className="text-[#5B5565]" size={16} />
        <p className="text-sm text-[#5B5565]">{location ?? "Not Spcified"}</p>
      </div>
    </div>
  );
};

export default JobInfoCard;
