import { sendMessage } from "@/utils/api/message"
import { useMutation } from "@tanstack/react-query"
import { useState } from "react"

const useSendMessage = () => {
  const [studentId, setStudentId] = useState<string>("")
  const [message, setMessage] = useState<string>("")
  const mutation = useMutation({
    mutationFn: () => sendMessage(studentId, message),
  })

  return {
    mutation,
    studentId,
    setStudentId,
    message,
    setMessage,
  }
}

export default useSendMessage