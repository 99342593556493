import { useEffect, useState, useCallback } from "react";
import { TalentSkill } from "@/types/common/talent";
import { SkillMultiSelect } from "../select/skill-multi-select";
import { Button } from "@/components/ui/button";
import { Plus, X } from "lucide-react";
import TextInput from "@/components/Input/TextInput";
import { useToast } from "@/components/ui/use-toast";
import { formatString } from "@/helpers/format_string";
import { TypographySmall } from "../typography/small";
import { convertToTitleCase } from "@/utils/helpers/string-helper";

const HARDCODE_SKILL_TYPE_LIST = ['HARD_SKILL', 'LANGUAGE'];

const HARDCODE_SKILL_MAP = {
  'HARD_SKILL': 'Skills',
  'LANGUAGE': 'Languages'
}

interface JobSkillTableProps {
  talentAttributeList: TalentSkill[];
  setTalentAttributeList: (talentAttributeList: TalentSkill[]) => void;
  additionalInfo: TalentSkill[];
  setAdditionalInfo: (additionalInfo: TalentSkill[]) => void;
}

export const TalentSkillTable = ({ talentAttributeList = [], setTalentAttributeList, additionalInfo = [], setAdditionalInfo }: JobSkillTableProps) => {
  const { toast } = useToast();
  const [newSkillType, setNewSkillType] = useState<string>("");
  const [skills, setSkills] = useState<Record<string, TalentSkill[]>>({});
  const [orderedSkills, setOrderedSkills] = useState<string[]>([]);

  useEffect(() => {
    if (Object.keys(skills).length > 0 || talentAttributeList.length === 0) return;

    const attributeList = [...additionalInfo, ...talentAttributeList.filter(talent => 
      !additionalInfo.some(add => add.name === talent.name)
    )];

    const skillMap = attributeList.reduce((acc, attribute) => {
      if (!acc[attribute.type]) acc[attribute.type] = [];
      acc[attribute.type].push(attribute);
      return acc;
    }, {} as Record<string, TalentSkill[]>);

    setOrderedSkills(Object.keys(skillMap));
    setSkills(skillMap);
  }, [talentAttributeList, additionalInfo]);

  const handleAddField = useCallback(() => {
    if (!newSkillType) {
      toast({ title: "Skill type cannot be empty 😢" });
      return;
    }
    if (skills[newSkillType] || HARDCODE_SKILL_TYPE_LIST.some(skillType => 
      convertToTitleCase(skillType).toUpperCase() === newSkillType.toUpperCase()
    )) {
      toast({ title: "Skill type already exists 😢" });
      return;
    }
    setSkills(prev => ({ ...prev, [newSkillType]: [] }));
    setOrderedSkills(prev => [...prev, newSkillType]);
    setNewSkillType("");
  }, [newSkillType, skills, toast]);

  const handleUpdateAttribute = useCallback((talentSkills: TalentSkill[]) => {
    setTalentAttributeList(talentSkills);
    setAdditionalInfo(talentSkills);
  }, [setTalentAttributeList, setAdditionalInfo]);

  const getCombinedSkills = useCallback((skillType: string, newSkills: TalentSkill[]): TalentSkill[] => {
    return Object.values({ ...skills, [skillType]: newSkills }).flat();
  }, [skills]);

  const removeSkillType = useCallback((skillType: string) => {
    if (window.confirm("Are you sure you want to remove this skill type?")) {
      setSkills(prev => {
        const { [skillType]: _, ...rest } = prev;
        return rest;
      });
      setOrderedSkills(prev => prev.filter(s => s !== skillType));
      handleUpdateAttribute(Object.values(skills).flat().filter(s => s.type !== skillType));
    }
  }, [skills, handleUpdateAttribute]);

  const renderSkillType = useCallback((skillType: string, index: number, isHardcoded: boolean) => (
    <div key={index} className="grid grid-cols-12 items-start gap-x-4">
      <div className="relative col-span-2">
        {isHardcoded ? (
          <div className="px-3 py-1 border rounded-md bg-white">
            <TypographySmall className="font-normal">{formatString(HARDCODE_SKILL_MAP[skillType as keyof typeof HARDCODE_SKILL_MAP] ? HARDCODE_SKILL_MAP[skillType as keyof typeof HARDCODE_SKILL_MAP] : skillType)}</TypographySmall>
          </div>
        ) : (
          <>
            <TextInput
              value={skillType}
              onChange={(e) => {
                const newValue = e.target.value;
                setSkills(prev => {
                  const { [skillType]: oldSkills, ...rest } = prev;
                  return { ...rest, [newValue]: oldSkills };
                });
                setOrderedSkills(prev => prev.map(s => s === skillType ? newValue : s));
              }}
              placeholder="Skill type"
              required
              max={20}
            />
            <button
              className="absolute top-1/2 right-3 -translate-y-1/2 z-20"
              onClick={() => removeSkillType(skillType)}
            >
              <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
            </button>
          </>
        )}
      </div>
      <div className="col-span-10">
        <SkillMultiSelect
          skillType={skillType}
          talentSkills={skills[skillType] || []}
          setTalentSkills={(talentSkills: TalentSkill[]) => {
            setSkills(prev => ({ ...prev, [skillType]: talentSkills }));
            handleUpdateAttribute(getCombinedSkills(skillType, talentSkills));
          }}
        />
      </div>
    </div>
  ), [skills, removeSkillType, handleUpdateAttribute, getCombinedSkills]);

  return (
    <div className="mb-4">
      {HARDCODE_SKILL_TYPE_LIST.map((skillType, index) => renderSkillType(skillType, index, true))}
      {Object.keys(skills)
        .sort((a, b) => orderedSkills.indexOf(a) - orderedSkills.indexOf(b))
        .filter(skillType => !HARDCODE_SKILL_TYPE_LIST.includes(skillType))
        .map((skillType, index) => renderSkillType(skillType, index, false))}
      <div className="flex gap-x-2 items-center">
        <TextInput
          value={newSkillType}
          onChange={(e) => setNewSkillType(e.target.value)}
          placeholder="Add new skill type"
          className="w-full h-10"
          required
          max={20}
        />
        <Button
          variant="outline"
          onClick={handleAddField}
          className="flex gap-x-1 border-secondary-900 text-secondary-900"
        >
          <Plus size={16} />
          Add Field
        </Button>
      </div>
    </div>
  );
};