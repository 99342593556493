"use client"

import React from 'react'
import { IoArrowForward } from 'react-icons/io5'
import { Button } from '@/components/ui/button'
import useDialogStore, { DialogProps } from '@/store/useDialogStore'

type NotificationDialogProps = {
    dialogProps: DialogProps,
}

const NotificationDialog: React.FC<NotificationDialogProps> = ({ dialogProps }) => {
  const { hideDialog } = useDialogStore();
  const handleConfirm = () => {
    if (!dialogProps?.onConfirm) return;
    dialogProps.onConfirm();
    hideDialog();
  }
  return (
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[70] outline-none focus:outline-none"
    >
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      <div className="relative w-1/3 my-6 mx-auto z-40">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none py-6">
          <div className="relative p-6 flex flex-col items-center gap-6 overflow-auto">
            <p className='text-2xl text-neutral-700 font-semibold text-center'>{dialogProps.title}</p>
            <p className='text-base text-neutral-600 text-center whitespace-pre-line'>{dialogProps.message}</p>
          </div>
          <div className="flex items-center justify-end pr-6 gap-4">
            {dialogProps?.onConfirm && 
            <Button 
              variant='outline'
              type='button' 
              className='bg-primary-500 hover:bg-primary-600 text-white hover:text-white rounded-md flex items-center gap-2'
              onClick={handleConfirm}
            >
              {dialogProps?.confirmText} <IoArrowForward color='white' />
            </Button>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationDialog
