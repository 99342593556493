import React from 'react'
import { Command, CommandItem, CommandList, CommandInput } from "@/components/ui/command";

const SearchInput:React.FC<React.ComponentProps<typeof CommandInput> & { searchResult?: string[] }> = ({ placeholder, searchResult = [], ...rest }) => {

  return (
    <Command>
      <CommandInput placeholder={placeholder} {...rest} />
      <CommandList>
        {searchResult.map((result) => (
          <CommandItem key={result}>
            {result}
          </CommandItem>
        ))}
      </CommandList>
    </Command>
  )
}

export default SearchInput