import { create } from 'zustand';

export type DialogType = 'LOADING' | 'NOTIFICATION' | 'ERROR' | 'CALIBRATE';

export type DialogProps = {
    title: string;
    message?: string;
    imageUrl?: string;
    imageWidth?: number;
    imageHeight?: number;
    onConfirm?: () => void;
    onCancel?: () => void;
    confirmText?: string;
    confirmUrl?: string;
    cancelText?: string;
}

interface DialogState {
  isShow: boolean;
  dialogType: DialogType;
  dialogProps: DialogProps;
}

interface DialogActions {
  showDialog: (dialogType: DialogType, dialogProps: DialogProps) => void;
  hideDialog: () => void;
}

const useDialogStore = create<DialogState & DialogActions>((set) => ({
  isShow: false,
  dialogType: 'ERROR',
  dialogProps: {
    title: '',
  },
  showDialog: (dialogType, dialogProps) => set({ isShow: true, dialogType, dialogProps }),
  hideDialog: () => set({ isShow: false, dialogType: 'ERROR', dialogProps: { title: '' } }),
}));

export default useDialogStore;
