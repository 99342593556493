import { getJob } from '@/utils/api/job'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react';

const useGetJob = () => {
  const [jobId, setJobId] = useState<string>("");
  const query = useQuery({
    queryKey: [jobId],
    queryFn: () => getJob(jobId),
    enabled: !!jobId,
  })

  return {
    query,
    jobId,
    setJobId,
  }
}

export default useGetJob