import { TalentSearchRequest } from "@/types/api/request/talent-search";
import axiosInstance from "./config";
import { TalentDetailResponse, TalentSearchResponse } from "@/types/api/response/admin";
import { AdminDownloadReportRequest } from "@/types/api/request/admin";

export async function getTalentSearch(data: TalentSearchRequest): Promise<TalentSearchResponse> {
  return axiosInstance.post('/admin/search/talent', data);
}

export async function getTalentDetail(id: string): Promise<TalentDetailResponse> {
  return axiosInstance.get(`/admin/talent`, { params: { id } });
}

export async function adminLogin(params: { token: string }) {
  return axiosInstance.post('/admin/login', null, { params });
}


export const downloadReport = async (data: AdminDownloadReportRequest): Promise<any> => {
  return axiosInstance.post('/admin/report', data, { responseType: 'arraybuffer' })
}

export const generateAdminReport = async (data: FormData): Promise<any> => {
  return axiosInstance.post('/admin/generate-report', data, {
    headers: { "Content-Type": "multipart/form-data" },
    responseType: 'blob'
  })
}