import { TypographyProps } from "@/components/customized-ui/typography/type"
import { cn } from "@/lib/utils"

export function TypographyH2({ children, className, ...rest }: TypographyProps<'h2'>) {
  return (
    <h2
      className={cn("scroll-m-20 text-3xl font-semibold tracking-tight first:mt-0", className)}
      {...rest}
    >
      {children}
    </h2>
  )
}