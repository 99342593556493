import React from 'react'
import BaseTab from './base-tab-content'
import { TalentExperience } from '@/types/common/talent';
import { TypographyP2 } from '../../typography/p2';

type ExperienceTabProps = {
  experiences: TalentExperience[];
}

const ExperienceTab: React.FC<ExperienceTabProps> = (props) => {
  return (
    <BaseTab title='Experience'>
      {
        props.experiences.map((experience, index) => (
          <div className='flex flex-col gap-1'>
            <TypographyP2 className='text-[#656565] font-semibold'>{experience.title}, {experience.companyName}</TypographyP2>
            <TypographyP2 className='text-[#656565] font-semibold'>{experience.startedAt} - {experience.endedAt ?? 'Present'}</TypographyP2>
            <ul className='list-disc list-inside'>
              {
                experience.description?.split('\r\n').map((line, index) => (
                  <li key={line+index} className='text-sm text-[#656565] whitespace-pre-line'>{line}</li>
                ))
              }
            </ul>
          </div>
        ))
      }
    </BaseTab>
  )
}

export default ExperienceTab