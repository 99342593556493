import { UserInfo } from '@/types/common/user';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface AuthState {
  isAuthenticated: boolean;
  user?: UserInfo;
  login: (userData: UserInfo) => void;
  logout: () => void;
}

const useAuthStore = create<AuthState>()(
  devtools(
    (set) => ({
      isAuthenticated: false,
      user: undefined,
      login: (userData) => set({ isAuthenticated: true, user: userData }, false, 'login'),
      logout: () => set({ isAuthenticated: false, user: undefined }, false, 'logout'),
    }),
    {
      name: 'Auth Store',
    }
  )
);

export default useAuthStore;
