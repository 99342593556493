import { Skeleton } from '@/components/ui/skeleton'

export const ListSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      {
        Array.from({ length: 5 }).map((_, index) => (
          <div key={index} className='flex flex-col gap-3 bg-white p-4 rounded-md shadow-md'>
            <Skeleton className="h-12 w-12 rounded-full" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px]" />
              <Skeleton className="h-4 w-[200px]" />
            </div>
          </div>
        ))
      }
    </div>
  )
}

export const DetailSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 bg-white p-4 shadow-md">
      <Skeleton className="h-12 w-12 rounded-full" />
      <div className="space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
      <div className="space-y-2">
        <Skeleton className="h-3 w-full" />
        <Skeleton className="h-3 w-full" />
        <Skeleton className="h-3 w-full" />
        <Skeleton className="h-3 w-full" />
      </div>
      <div className="space-y-2">
        <Skeleton className="h-80 w-full" />
      </div>
      <div className="space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-full" />
      </div>
    </div>)
}