import { searchJobs } from "@/utils/api/job"
import { useMutation } from "@tanstack/react-query"

const useGetJobList = () => {
  return useMutation({
    mutationFn: searchJobs,
  })
}

export default useGetJobList
