import { SearchJobsRequest } from "@/types/api/request/job";
import { GetJobResponse, SearchJobsResponse } from "@/types/api/response/job";
import axiosInstance from "./config";

export const searchJobs = async (body: SearchJobsRequest): Promise<SearchJobsResponse> => {
  return axiosInstance.post("/job/search", body)
}

export const getJob = async (id: string): Promise<GetJobResponse> => {
  return axiosInstance.get(`/job/${id}`)
}