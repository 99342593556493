import React from 'react'
import CustomCard, { CustomCardProps } from '@/components/customized-ui/card/custom-card'
import { Mail } from 'lucide-react'
import { IoPersonOutline } from 'react-icons/io5'

type TalentInfoCardProps = {
    name: string
    email: string
    id: string,
} & CustomCardProps

const TalentInfoCard: React.FC<TalentInfoCardProps> = ({ name, email, id, ...rest }) => {
  return (
    <CustomCard
      {...rest}
      title={name}
      cardContent={
        <div className="flex flex-col gap-2">
          <p className='flex gap-1 items-center text-sm'><Mail size={14} />{email}</p>
          <p className='flex gap-1 items-center text-sm'><IoPersonOutline size={14} />{id}</p>
        </div>
      }
    />
  )
}

export default TalentInfoCard