export function removeSpecialCharacters(text: string) {
  const chars = [];
  let isContinue = false;

  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    if (char === '&') {
      isContinue = true;
      continue;
    }
    if (char === ';') {
      isContinue = false;
      continue;
    }
    if (isContinue) continue;
    chars.push(char);
  }
  return chars.join('');
}

export function convertToTitleCase(text: string) {
  return text.split('_').map((word) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
}