import { Button } from '@/components/ui/button';
import React from 'react';

interface PaginationProps {
  currentPage: number;
  setCurrentPage: (pageNumber: number) => void;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ totalItems, itemsPerPage, onPageChange, currentPage, setCurrentPage }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber);
  };

  const renderPageNumbers = () => {
    const visiblePages = 5;
    const halfVisiblePages = Math.floor(visiblePages / 2);
  
    const pageNumbers = [];
  
    for (let i = Math.max(1, currentPage - halfVisiblePages); i <= Math.min(totalPages, currentPage + halfVisiblePages); i++) {
      pageNumbers.push(
        <Button
          key={i}
          className={`${
            i === currentPage ? 'bg-primary-500 hover:bg-primary-600 text-white' : 'bg-gray-200 hover:bg-gray-300 text-gray-700'
          } inline-block cursor-pointer`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Button>
      );
    }
  
    if (currentPage > halfVisiblePages + 1) {
      pageNumbers.unshift(<span key="start-ellipsis" className="inline-block px-2">...</span>);
    }
  
    if (currentPage < totalPages - halfVisiblePages) {
      pageNumbers.push(<span key="end-ellipsis" className="inline-block px-2">...</span>);
    }
  
    return pageNumbers;
  };

  return (
    <div className="flex justify-center mt-4">
      <ul className="flex space-x-2">
        <Button
          className={`${
            currentPage === 1 ? 'bg-gray-200 hover:bg-gray-300 text-gray-700' : 'bg-primary-500 hover:bg-primary-600 text-white'
          } inline-block cursor-pointer`}
          onClick={() => handlePageChange(1)}
        >
          First
        </Button>
        {renderPageNumbers()}
        <Button
          className={`${
            currentPage === totalPages ? 'bg-gray-200 hover:bg-gray-300 text-gray-700' : 'bg-primary-500 hover:bg-primary-600 text-white'
          } inline-block cursor-pointer`}
          onClick={() => handlePageChange(totalPages)}
        >
          Last
        </Button>
      </ul>
    </div>
  );
};

export default Pagination;
