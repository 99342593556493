import { TalentCertification } from "@/types/common/talent";
import { Content } from "pdfmake/interfaces";
import { ContentCreator } from "@/lib/pdf/interface";
import sectionGenerator from "../components/section";

export default class CertificationContentCreator implements ContentCreator {
  constructor(private readonly talentCertification: TalentCertification[]) {}

  async create(): Promise<Content> {
    return sectionGenerator({
      header: 'AWARDS & CERTIFICATIONS',
      sectionContent: this.talentCertification.map(({ name, description, authority, awardedAt }) => ({
        title: name && authority ? `${name}, ${authority}` : (name || authority),
        startDate: awardedAt,
        endDate: awardedAt,
        relatedParty: '',
        description
      })),
      isCombineRelatedParty: false
    });
  }
}