export function formatString(str: string) {
  if (!str || typeof str !== 'string') return '';
  const words = str?.split('_');
  const capitalizedWords = words.map((word: string) => {
    word = word.trim();
    return word.split('').map((char, index) => index === 0 ? char.toUpperCase() : char).join('');
  });
  return capitalizedWords.join(' ');
}

export function formatSimilarityScore(score: number) {
  // return result based on score
  if (score >= 0.8) return 'Highly Aligned';
  if (score >= 0.6) return 'High';
  if (score >= 0.4) return 'Medium';
  if (score >= 0.2) return 'Low';
  return 'Very Low';
}