import { TypographyProps } from "@/components/customized-ui/typography/type"
import { cn } from "@/lib/utils"

export function TypographyP({ children, className, ...rest }: TypographyProps<'p'>) {
  return (
    <p
      className={cn("", className)}
      {...rest}
    >
      {children}
    </p>
  )
}
  