"use client"

import React from 'react'
import { IoArrowForward } from 'react-icons/io5'
import { Button } from '@/components/ui/button'
import useDialogStore, { DialogProps } from '@/store/useDialogStore'
import { generateAdminReport } from '@/utils/api/admin'
import { PasswordInput } from '@/components/Input/PasswordInput'
import { Label } from '@/components/ui/label'
import { AdminGenerateReportRequest } from '@/types/api/request/admin'
import { apiErrorContent } from '@/utils/api/constants'

type DownloadReportDialogProps = {
    dialogProps: DialogProps;
    reportName: string;
    reportContent: AdminGenerateReportRequest
    onClose: () => void;
}

const DownloadReportDialog: React.FC<DownloadReportDialogProps> = ({ dialogProps, reportName, onClose, reportContent }) => {
  const { showDialog, hideDialog } = useDialogStore();
  const [password, setPassword] = React.useState('')

  const handleDownloadReport = async (e: any) => {
    e.preventDefault();
    onClose()
    showDialog('LOADING', { title: 'Downloading report...' })
    try {
      await handleReport();
      showDialog('NOTIFICATION', 
        { 
          title: 'Download complete', 
          message: 'The report has been downloaded successfully.',
          confirmText: 'OK',
          onConfirm: () => {
            hideDialog()
          },
        })
    } catch (error) {
      showDialog('ERROR', { 
        title: apiErrorContent.title,
        message: apiErrorContent.message,
        imageUrl: apiErrorContent.imageUrl,
        confirmText: apiErrorContent.confirmText,
        onConfirm: () => {
          hideDialog()
        },
      })
    }
    
  }

  const handleReport = async () => {
    const form = new FormData();
    form.append('file', reportContent.file)
    form.append('type', reportContent.type)
    form.append('password', password)
    form.append('facultyCol', reportContent.facultyCol)
    form.append('progCol', reportContent.progCol)
    if (reportContent.type === 'monthly_salary') {
      form.append('salaryCol', reportContent.salaryCol)
    } else if (reportContent.type === 'employment_status') {
      form.append('empStatusCol', reportContent.empStatusCol)
    }
    form.append('targetFaculty', reportContent.targetFaculty)
    form.append('sheetNumber', reportContent.sheetNumber)
    form.append('year', reportContent.year)
    const blob = await generateAdminReport(form)
    const a = document.createElement('a')
    a.href = URL.createObjectURL(new Blob(
      [blob],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
    ))
    a.download = reportName
    a.click()
  }

  return (
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[40] outline-none focus:outline-none"
    >
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      <form className="relative w-1/3 my-6 mx-auto z-40" onSubmit={handleDownloadReport}>
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none py-6 px-10">
          <div className="relative p-6 flex flex-col items-center gap-6 overflow-auto">
            <p className='text-2xl text-neutral-700 font-semibold text-center'>{dialogProps.title}</p>
            <p className='text-base text-neutral-600 text-center whitespace-pre-line'>{dialogProps.message}</p>
          </div>
          <Label className='mb-1'>Password</Label>
          <PasswordInput
            placeholder='Enter password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="new-password"
            required
          />
          <div className="flex items-center justify-between gap-4 mt-6">
            <Button
              variant='outline'
              type='button'
              onClick={onClose}
            >
              Cancel
            </Button>
            {dialogProps?.onConfirm && 
            <Button 
              variant='outline'
              type='submit' 
              className='bg-primary-800 hover:bg-primary-900 text-white hover:text-white rounded-md flex items-center gap-2'
            >
              {dialogProps?.confirmText} <IoArrowForward color='white' />
            </Button>}
          </div>
        </div>
      </form>
    </div>
  )
}

export default DownloadReportDialog
