import React from 'react'
import { Button } from '../ui/button';
import { PencilLineIcon } from 'lucide-react';
import { Subheading } from '../customized-ui/typography/subheading';
import { P1 } from '../customized-ui/typography/p1';
import useDialogStore, { DialogProps } from '@/store/useDialogStore';

type SectionProps = {
  title: string,
  message?: React.ReactNode,
  children: React.ReactNode,
  dialogProps?: DialogProps
  isHideActionButton?: boolean
  actionButtonChildren?: React.ReactNode
}

const Section: React.FC<SectionProps> = ({ title, children, message, isHideActionButton: isHideAddButton=false, actionButtonChildren }) => {
  return (
    <div>
      <div className="flex gap-x-2 items-center mt-6 mb-2">
        <PencilLineIcon size={16} className='text-secondary-800' />
        <Subheading className='font-semibold text-neutral-700'>{title}</Subheading>
      </div>
      {message && (
        <P1 className="text-gray-700 mb-2">{message}</P1>
      )}
      <div className='flex flex-col gap-3'>
        {children}
      </div>
      {!isHideAddButton ?<Button
        variant='outline'
        className='my-6 text-secondary-800 hover:text-secondary-800 border-secondary-800'
        onClick={() => {
          window.location.href = process.env.REACT_APP_PROFILE_URL || ''
        }}
      >
        {actionButtonChildren}
      </Button> : null}
    </div>
  )
}

export default Section