import { getTalentDetail } from "@/utils/api/admin";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

function useTalentDetail() {
  const [id, setId] = useState<string>('');
  const query = useQuery({
    queryKey: [id],
    queryFn: () => getTalentDetail(id),
    enabled: !!id,
  })

  return {
    query,
    setId,
    id,
  }
}

export default useTalentDetail;