import { getUserInfo } from "@/utils/api/user"
import { useQuery } from "@tanstack/react-query"

const useGetUserInfo = () => {
  return useQuery({
    queryKey: ['user'],
    queryFn: getUserInfo,
  })
}

export default useGetUserInfo