import AdminInsightCard from '@/components/customized-ui/card/admin-insight-card'
import InsightTable from '@/components/customized-ui/table/insight-table'
import { TypographyH3 } from '@/components/customized-ui/typography/h3'
import { Card } from '@/components/ui/card'
import useGetInsight from '@/hooks/api/insight/get-insight'
import React, { useEffect } from 'react'
import UG_INSIGHT from '@/constants/insight/ug.json'
import PG_INSIGHT from '@/constants/insight/pg.json'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { format } from 'date-fns'

const AdminInsightPage = () => {
  // const [activeSlide, setActiveSlide] = React.useState(0)
  const [insightType, setInsightType] = React.useState<'UG'|'PG'|'SYSTEM'>('UG')
  const [insight, setInsight] = React.useState(UG_INSIGHT);
  const { data: realTimeInsight, setEndedAt } = useGetInsight();

  useEffect(() => {
    setEndedAt(format(new Date(), 'yyyy-MM-dd'))
  }, [])

  // const insightData = [
  //   <InsightTable
  //     heads={['Most Popular Skill', 'No. Occurrences']}
  //     rows={[
  //       ['Python', '50'],
  //       ['Presentation', '60'],
  //       ['Coding', '40'],
  //     ]}
  //   />,
  //   <AdminChartCard
  //     title='User Demographic by Program'
  //     labels={['Chi Lang', 'Finance', 'Psychology', 'Biology', 'Design', 'Others']}
  //     data={[50, 60, 40, 30, 20, 10]}
  //   />,
  //   <AdminChartCard
  //     title='Most Clicked Courses by Skills'
  //     labels={['Language', 'Design', 'Accounting', 'Speaking', 'Business', 'Interview']}
  //     data={[50, 60, 40, 30, 20, 10]}
  //   />
  // ]

  const onInsightTypeChange = (type: 'UG'|'PG'|'SYSTEM') => {
    setInsightType(type)
    if (type === 'UG') {
      setInsight(UG_INSIGHT)
    } else {
      setInsight(PG_INSIGHT)
    }
  }

  // const onPrevSlide = () => {
  //   if (activeSlide === 0) {
  //     setActiveSlide(insightData.length - 1)
  //     return
  //   }
  //   setActiveSlide(activeSlide - 1)
  // }

  // const onNextSlide = () => {
  //   if (activeSlide === insightData.length - 1) {
  //     setActiveSlide(0)
  //     return
  //   }
  //   setActiveSlide(activeSlide + 1)
  // }
  
  return (
    <div className='max-w-6xl mx-auto py-4'>
      <TypographyH3>Key Insight</TypographyH3>
      <div className='flex gap-2 mt-4'>
        <Button
          className={cn('rounded-full bg-transparent hover:bg-primary-500 text-primary-500 hover:text-white border-2 border-primary-500', insightType==='UG' ? 'bg-primary-500 text-white' : 'border-primary-500')}
          onClick={() => onInsightTypeChange('UG')}>
          Undergraduates
        </Button>
        <Button 
          className={cn('rounded-full bg-transparent hover:bg-primary-500 text-primary-500 hover:text-white border-2 border-primary-500', insightType==='PG' ? 'bg-primary-500 text-white' : 'border-primary-500')} 
          onClick={() => onInsightTypeChange('PG')}>
          Postgraduates
        </Button>
        <Button 
          className={cn('rounded-full bg-transparent hover:bg-primary-500 text-primary-500 hover:text-white border-2 border-primary-500', insightType==='SYSTEM' ? 'bg-primary-500 text-white' : 'border-primary-500')} 
          onClick={() => onInsightTypeChange('SYSTEM')}>
          System
        </Button>
      </div>
      {
        insightType === 'SYSTEM' && realTimeInsight && (
          <div className='col-span-2 flex flex-col gap-2 my-6 max-w-sm'>
            {
              [
                { title: 'Monthly Active Users', highlight: realTimeInsight?.reduce((prev, curr) => { prev += curr.loginCount; return prev; }, 0) ?? 0 },
                { title: 'Total Resumes Created', highlight: realTimeInsight?.reduce((prev, curr) => { return prev + curr.downloadResumeCount }, 0) ?? 0 },
                { title: 'Total Experience Generated', highlight: realTimeInsight?.reduce((prev, curr) => { return prev + curr.generateExperienceCount }, 0) ?? 0 },
                { title: 'Total Summary Generated', highlight: realTimeInsight?.reduce((prev, curr) => { return prev + curr.generateSummaryCount }, 0) ?? 0 },
              ].map((item) => (
                <Card key={item.title}>
                  <div className='flex justify-between items-center px-4 py-2'>
                    <p className='text-sm text-neutral-800'>{item.title}</p>
                    <p className='text-xl text-neutral-900 font-semibold'>{item.highlight}</p>
                  </div>
                </Card>
              ))
            }
          </div>
        )
      }
      {
        insightType !== 'SYSTEM' && 
        <>
          <div className="grid grid-cols-4 gap-2 my-6">
            {
              insight.highlight.map((item, index) => (
                <AdminInsightCard key={index} {...item} />
              ))
            }
          </div>
          <div className='col-span-3'>
            <InsightTable
              heads={['Top Earnings Disparity Faculties', 'Min  (HK$)', 'Max  (HK$)']}
              rows={insight['Top Earnings Disparity Faculties'].map((item) => [item.faculty, item.min, item.max])}
            />
          </div>
          <div className='my-4'>
            <div>
              <InsightTable
                heads={['Top Earnings Industries', 'Most Employed Programme', 'Average (HK$)']}
                rows={insight['Top Earnings Industries'].map((item) => [item.industry, item.program, item.avg])}
              />
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default AdminInsightPage