import React from 'react'
import { Card } from '@/components/ui/card'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/ui/table'
import {cn} from "@/lib/utils";

type InsightTableProps = {
    heads: string[],
    rows: string[][],
}

const InsightTable: React.FC<InsightTableProps> = (props) => {
  return (
    <Card className='p-4'>
      <Table>
        <TableHeader>
          <TableRow>
            {props.heads.map((head, index) => (
              <TableHead key={head} className={cn("whitespace-nowrap", index !== 0 ? "text-right w-[200px]" : "")}>{head}</TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {props.rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <TableCell key={cellIndex} className={cellIndex !== 0 ? "text-right w-[200px]" : ""}>
                  {cellIndex === 0 ? <span className='font-semibold'>{rowIndex+1} {cell}</span> : cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  )
}

export default InsightTable