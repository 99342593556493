import React, { useEffect } from 'react'
import JobDetailBox from '@/components/customized-ui/card/job-detail-card'
import { DetailSkeleton } from '@/components/customized-ui/skeleton/job-page-skeleton'
import useGetJob from '@/hooks/api/job/get-job';
import { useParams } from 'react-router-dom';

const JobDetailPage = () => { 
  const { id: jobId } = useParams();
  const { query: { data: jobDetail, isLoading: isLoadingJobDetail }, setJobId } = useGetJob();

  useEffect(() => {
    if (jobId) {
      setJobId(jobId);
    }
  }, []);

  return (
    <div className='max-w-6xl mx-auto py-4'>
      {isLoadingJobDetail ? 
        <DetailSkeleton/> :
        typeof jobDetail === 'object' && <JobDetailBox jobDetail={jobDetail} />}
    </div>
  )
}

export default JobDetailPage