import React from 'react'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs'

type CustomTabsProps = {
    tabs: string[];
    nodes: Record<string,React.ReactNode>;
}

const CustomTabs: React.FC<CustomTabsProps> = (props) => {
  return (
    <Tabs defaultValue={props.tabs[0]} className="w-full">
      <TabsList className='border-2 border-b-0 border-primary-100 rounded-none rounded-t-lg bg-white'>
        {props.tabs.map((tab) => (
          <TabsTrigger key={tab} value={tab} className='data-[state=active]:bg-primary-100 data-[state=active]:text-primary-800'>{tab}</TabsTrigger>
        ))}
      </TabsList>
      {
        props.tabs.map((tab) => (
          <TabsContent key={tab} value={tab} className='border-2 border-primary-100 rounded-b-lg rounded-tr-lg m-0 p-4'>
            {props.nodes[tab]}
          </TabsContent>
        ))
      }
    </Tabs>
  )
}

export default CustomTabs