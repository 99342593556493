import { TalentQualification } from "@/types/common/talent";
import { Content } from "pdfmake/interfaces";
import { ContentCreator } from "@/lib/pdf/interface";
import sectionGenerator from "../components/section";

export default class QualificationContentCreator implements ContentCreator {
  constructor(private readonly talentQualification: TalentQualification[]) {}

  async create(): Promise<Content> {
    return sectionGenerator({
      header: 'EDUCATION',
      sectionContent: this.talentQualification.map(({ major, startedAt, endedAt, institution, description, type }) => ({
        title: institution || '',
        startDate: startedAt,
        endDate: endedAt,
        relatedParty: type ? `${type} in ${major}` : major || '',
        description
      })),
      isCombineRelatedParty: false
    });
  }
}