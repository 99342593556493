import axiosInstance from "./config";
import { TalentEnrichmentRequest, TalentTemplateRequest } from "@/types/api/request/talent";
import { TalentProfile } from "@/types/common/talent";

export const getTalentProfile = async(): Promise<TalentProfile> => {
  const response: any = await axiosInstance.get('/talent/profile')
  response.talentInfo.additionalInfo = response.talentInfo.additionalInfo ? JSON.parse(response.talentInfo.additionalInfo) : {}
  return response;
}

export const updateTalentProfile = async(body: any): Promise<TalentProfile> => {
  return axiosInstance.put('/talent/profile', body)
}

export const talentEnrichment = async(body: TalentEnrichmentRequest): Promise<string> => {
  return axiosInstance.post('/talent/enrichment', body)
}

export const talentTemplate = async(params: TalentTemplateRequest): Promise<string[]> => {
  return axiosInstance.get('/talent/template', { params })
}

export const talentCalibrate = async() => {
  return axiosInstance.post('/talent/calibrate')
}