import React from 'react'
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '@/components/ui/card'
import { Button } from '@/components/ui/button';
import { IoClose } from 'react-icons/io5';
import { Input } from '../../ui/input';
import { ArrowRight } from 'lucide-react';
import useSendMessage from '@/hooks/api/message/send-message';
import useGetUserInfo from '@/hooks/api/user/get-user-info';
import { LoadingSpinner } from '../animate/loading-spinner';
import { useToast } from '@/components/ui/use-toast';

type Tips = {
    title: string;
    subTitle: string;
    description: string;
}

const tips: Tips[] = [
  { title: "Getting Started", subTitle: "Ready to dive into the job hunt? ", description: "Start by pinpointing the job you're aiming for —remember, a custom-fit resume is a favorite among 62% of recruiters!" },
  { title: "Summary", subTitle: "Craft a clear objective statement.", description: "Does your selected summary reflect the role you're after? Did you update brackets to accurate information?" },
  { title: "Education", subTitle: "Showcase your educational accomplishments.", description: "If you've got a stellar GPA, don't be shy to flaunt it in a bracket next to the title of your degree!" },
  { title: "Experience", subTitle: "List any internships, part-time jobs, or projects!", description: "Can you quantify your achievements with numbers or outcomes? Replace the brackets with your own experience." },
  { title: "Skills", subTitle: "Check with the job description.", description: "Did you add skills relevant to the job? Are all skills relevant to your target job?" },
  { title: "Additional Sections", subTitle: "Do you have extra-curriculars or volunteer work?", description: "How do they demonstrate skills that make you a star at the workplace?" },
  { title: "Fine-tuning", subTitle: "What do you see in your resume in 6 seconds?", description: "Recruiters only spend seconds on one resume. Is your resume language crisp and easy to understand?" },
]

const TipsCard: React.FC<React.ComponentProps<typeof Card>> = (props) => {
  const [step, setStep] = React.useState(0)
  const { data: userInfo } = useGetUserInfo();
  const { mutation: { mutateAsync: sendMessage, isPending: isPendingSendMessage }, setStudentId, setMessage, message } = useSendMessage();
  const { toast } = useToast();

  const onNextStep = () => {
    if (step === tips.length - 1) {
      localStorage.setItem("hideTips", "true")
      setStep(tips.length)
      return
    }
    setStep(step + 1)
  }

  const onPrevStep = () => {
    setStep(step - 1)
  }

  const handleCompleteTips = () => {
    localStorage.setItem("hideTips", "true")
    setStep(tips.length)
  }

  const handleSendMessage = async () => {
    if (!userInfo) return;
    setStudentId(userInfo?.studentId);
    await sendMessage();
    handleCompleteTips();
    toast({
      title: "Message Sent 🎉"
    })
  }

  if (step === tips.length) return null

  return (
    <Card {...props}>
      <IoClose className='absolute top-4 right-4 text-neutral-800 cursor-pointer' onClick={handleCompleteTips} />
      <CardHeader className='mt-4'>
        <CardTitle>
          <div className='flex justify-between'>
            <h1 className='text-neutral-800 text-base'>{tips[step].title}</h1>
            <h1 className='text-primary-800 text-sm'>Step {step + 1}/{tips.length}</h1>
          </div>
        </CardTitle>
        <CardDescription className='text-base text-neutral-800'>{tips[step].subTitle}</CardDescription>
      </CardHeader>
      <CardContent className='text-sm text-neutral-800'>
        {tips[step].description}
        {
          step === tips.length - 1 && (
            <form className='relative' onSubmit={(e) => {
              e.preventDefault();
              handleSendMessage();
            }}>
              <Input name='message' value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Hi, please review my resume...' className='mt-2' required />
              <Button
                type='submit'
                className='absolute top-1/2 right-1 -translate-y-1/2 h-7 w-10 bg-primary-700 hover:bg-primary-800 p-0'
                disabled={isPendingSendMessage || !message}
              >
                {isPendingSendMessage ? <LoadingSpinner/> : <ArrowRight size={16} />}
              </Button>
            </form>
          )
        }
      </CardContent>
      <CardFooter>
        <div className='w-full flex justify-between'>
          {step !== 0 && 
          <Button
            variant='ghost'
            onClick={onPrevStep}
            disabled={step === 0 || isPendingSendMessage}
          >
            Back
          </Button>}
          <Button
            variant='ghost'
            className='text-primary-400 hover:text-primary-500 ml-auto'
            onClick={onNextStep}
            disabled={isPendingSendMessage}
          >
            {step === tips.length - 1 ? 'Done' : 'Next'}
          </Button>
        </div>
      </CardFooter>
    </Card>
  )
}

export default TipsCard