import { TypographyProps } from "@/components/customized-ui/typography/type"
import { cn } from "@/lib/utils"

export function TypographyList({ children, className, ...rest }: TypographyProps<'ul'>) {
  return (
    <ul
      className={cn("list-disc [&>li]:mt-2 flex flex-col gap-4", className)}
      {...rest}
    >
      {children}
    </ul>
  )
}
  