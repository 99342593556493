import React from 'react'
import { cn } from '@/lib/utils'

interface H1Props extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode
}

const H1 = React.forwardRef<HTMLHeadingElement, H1Props>(
  ({ className, children, ...props }, ref) => {
    return (
      <h1
        className={cn(
          "scroll-m-20 text-4xl font-semibold tracking-tight text-neutral-800 lg:text-5xl",
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </h1>
    )
  }
)

H1.displayName = "H1"

export { H1 }
