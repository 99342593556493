import React from 'react'
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { TypographyMuted } from '../typography/muted';

type TextAreaInputProps = {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    label: string;
    description?: string;
    placeholder: string;
}

const TextAreaInput = ({ value, onChange, label, description, placeholder }: TextAreaInputProps) => {
  return (
    <div className='flex flex-col gap-1'>
      <Label className='text-neutral-900 text-base'>{label}</Label>
      {
        description && 
        <TypographyMuted className='text-neutral-500'>{description}</TypographyMuted>
      }
      <Textarea
        className='rounded-md overflow-auto px-3 py-1 mt-1 h-32 resize-none bg-white'
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  )
}

export default TextAreaInput