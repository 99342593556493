import React from 'react'
import BaseTab from '@/components/customized-ui/tab/content/base-tab-content'
import { Separator } from '@/components/ui/separator'
import { Job } from '@/types/common/job'

type TopTenJobMatchesTabProps = {
  jobList: Job[];
}

const TopTenJobMatchesTab: React.FC<TopTenJobMatchesTabProps> = (props) => {
  return (
    <BaseTab title='Top 10 Job Matches'>
      <div className='max-h-96 overflow-auto'>
        {
          props.jobList.map((job, index) => (
            <>
              <div className='flex items-center gap-6 my-2'>
                <p className='text-sm text-neutral-600 font-semibold'>{index+1}</p>
                <div className='flex flex-col gap-2'>
                  <p className='text-sm text-neutral-600'>{job.title} at {job.companyName}</p>
                  <div className='flex gap-2 text-xs text-neutral-600'>
                    <div className='flex-row'>
                      <p className='mb-1'>Origin: <a className="text-blue-600" href={job.applicationMethod}>{job.applicationMethod}</a></p>
                      <p className='mb-1'>Industry: {job.industry.name}</p>
                      <p className='mb-1'>Job Nature: {job.jobNature.name}</p>
                    </div>
                  </div>
                </div>
              </div>
              <Separator className='bg-primary-50' />
            </>
          ))
        }
      </div>
    </BaseTab>
  )
}

export default TopTenJobMatchesTab