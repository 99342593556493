import { getJobNature } from "@/utils/api/dictionary"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react";

const useGetJobNature = () => {
  const [keyword, setKeyword] = useState<string>("");
  
  const query = useQuery({
    queryKey: ["job-nature"],
    queryFn: getJobNature,
  })

  return {
    ...query,
    keyword,
    setKeyword
  }
}

export default useGetJobNature