import { Button } from '@/components/ui/button';
import React from 'react';

interface ErrorPageProps {
  errorCode: number;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ errorCode }) => {
  const isAdmin = window.location.pathname.includes('/admin')
  return (
    <div className="h-[calc(100vh_-_64px)] flex flex-col justify-center items-center">
      <div className="max-w-md w-full text-center">
        <h2 className="text-4xl font-bold text-red-500 mb-4">Error {errorCode}</h2>
        <p className="text-lg text-gray-700 mb-4">Oops! Something went wrong.</p>
        <p className="text-sm text-gray-500">The page you're looking for might have been removed or doesn't exist. Try refreshing the page or go back to the home page.</p>
        <Button
          className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold"
          onClick={() => window.location.href = isAdmin ? '/admin' : '/'}
        >
          Go Home
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
