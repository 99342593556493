import { Content } from "pdfmake/interfaces";
import { ContentCreator } from "@/lib/pdf/interface";
import { formatString } from "@/helpers/format_string";

const ATTRIBUTE_MAPPING = {
  HARD_SKILL: 'Skills',
  LANGUAGE: 'Languages',
}

export default class AttributeContentCreator implements ContentCreator {
  constructor(private readonly talentAttributeList: {id: string, name: string, type: string }[]) {}

  private skillContent(skillType: string): Content {
    const skillsOfType = this.talentAttributeList.filter(attr => attr.type === skillType);
    if (skillsOfType.length === 0) return [];

    return {
      text: [
        { text: `${
          ATTRIBUTE_MAPPING[skillType as keyof typeof ATTRIBUTE_MAPPING] || formatString(skillType)
        }: `, style: 'subheader', alignment: 'left', bold: true },
        { text: skillsOfType.map(attr => attr.name).join(', '), style: 'subheader', alignment: 'left' },
      ]
    };
  }

  async create(): Promise<Content> {
    if (this.talentAttributeList.length === 0) return [];

    const content: Content = [
      { text: 'SKILLS & OTHER INTERESTS', style: 'header', bold: true, alignment: 'left' },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }], margin: [0, 4, 0, 4] },
    ];

    const skillTypes = Array.from(new Set(this.talentAttributeList.map(skill => skill.type)));

    skillTypes.forEach(skillType => {
      if (skillType) {
        content.push(this.skillContent(skillType));
        content.push({ canvas: [], margin: [0, 0, 0, 2] });
      }
    });

    return content;
  }
}