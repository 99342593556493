
import { TalentProfile, TalentSkill } from "@/types/common/talent";
import pdfMake from "pdfmake/build/pdfmake";
import { Content } from "pdfmake/interfaces";
import { ContentCreator, PDFGenerator, PDFStream } from "../interface";
import PdfPrinter from "pdfmake";
import HeaderContentCreator from "./content/header";
import SummaryContentCreator from "./content/summary";
import ExperienceContentCreator from "./content/experience";
import QualificationContentCreator from "./content/qualification";
import AttributeContentCreator from "./content/attribute";
import { FONTS } from "./template";
import vfs from "../../../fonts/vfs_fonts_times";
import ExtraCurricularActivityContentCreator from "./content/extra-curricular";
import CertificationContentCreator from "./content/certificate";

pdfMake.vfs = pdfMake.vfs = vfs;
pdfMake.fonts = FONTS;

interface CreatorClass<T> {
    new (data: T): ContentCreator;
}


export class ResumeGenerator implements PDFGenerator {
  readonly talentProfile: TalentProfile;
  private combinedAttributes: TalentSkill[];
  
  constructor(talentProfile: TalentProfile) {
    this.talentProfile = talentProfile;
    const attributeList: TalentSkill[] = [];

    try {
      const additionalInfoJson = JSON.parse(this.talentProfile.talentInfo.additionalInfo as any);

      additionalInfoJson.attribute?.forEach((additional: TalentSkill) => {
        attributeList.push(additional);
      });
    } catch (error) {
      console.log(error);
    }

    this.talentProfile.talentAttributeList?.forEach((talentAttribute) => {
      if (!attributeList.find((attribute) => attribute.name === talentAttribute.name)) {
        attributeList.push(talentAttribute);
      }
    });

    this.combinedAttributes = attributeList;
  }

  async create(): Promise<PDFStream> {
    const printer = new PdfPrinter({});
    const docDefinition = { content: [] };
    const doc = printer.createPdfKitDocument(docDefinition);
    return doc;
  }

  download = async () => {
    const content = await this._createContents();
    const pdf = pdfMake.createPdf({ content, defaultStyle: { font: 'TimesNewRoman' } });
    pdf.download('resume-test.pdf'); // You can also use pdf.open() to open in a new tab
  };

  private async _createContents(): Promise<Content[]> {
    const contents: Content[] = [];
    const MARGIN_BOTTOM = 8
    contents.push(await this._createContent(HeaderContentCreator, this.talentProfile.talentInfo));
    contents.push({ canvas: [], marginBottom: MARGIN_BOTTOM });
    contents.push(await this._createContent(SummaryContentCreator, this.talentProfile.talentInfo));
    contents.push({ canvas: [], marginBottom: MARGIN_BOTTOM });
    contents.push(await this._createContent(QualificationContentCreator, this.talentProfile.talentQualificationList));
    contents.push({ canvas: [], marginBottom: MARGIN_BOTTOM });
    contents.push(await this._createContent(ExperienceContentCreator, this.talentProfile.talentExperienceList));
    contents.push({ canvas: [], marginBottom: MARGIN_BOTTOM });
    contents.push(await this._createContent(ExtraCurricularActivityContentCreator, this.talentProfile.talentExtraCurricularActivityList));
    contents.push({ canvas: [], marginBottom: MARGIN_BOTTOM });
    contents.push(await this._createContent(CertificationContentCreator, this.talentProfile.talentCertificationList));
    contents.push({ canvas: [], marginBottom: MARGIN_BOTTOM });
    contents.push(await this._createContent(AttributeContentCreator, this.combinedAttributes));
    return Promise.resolve(contents);
  }

  private async _createContent<T>(creator: CreatorClass<T>, data: T): Promise<Content> {
    return await new creator(data).create();
  }
}