import { LoginRequest } from "@/types/api/request/user";
import { GenerateTokenResponse, GetUserInfoResponse, LoginResponse } from "@/types/api/response/user";
import axiosInstance from "./config";

export const generateToken = async (): Promise<GenerateTokenResponse> => {
  return axiosInstance.post("/user/dev-generate-token")
}

export const login = async (params: LoginRequest): Promise<LoginResponse> => {
  return axiosInstance.post("/user/login", null, { params})
}

export const logoutUtil = async (): Promise<void> => {
  return axiosInstance.post("/user/logout")
}

export const getUserInfo = async (): Promise<GetUserInfoResponse> => {
  return axiosInstance.get("/user/info")
};