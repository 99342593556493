"use client"

import React from 'react'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import useDialogStore, { DialogProps } from '@/store/useDialogStore'

type LoadingDialogProps = {
    dialogProps: DialogProps,
}

const LoadingDialog: React.FC<LoadingDialogProps> = ({ dialogProps }) => {
  const { hideDialog } = useDialogStore();
  return (
    <Dialog open={true} onOpenChange={hideDialog}>
      <DialogContent disableCloseButton>
        <div className="relative p-6 flex flex-col items-center gap-6 py-20 overflow-auto">
          <img src="/app-logo.svg" alt="sota" width={70} height={35} />
          <img src="/spinner.gif" alt="loading" width={80} height={80} />
          <p className='text-2xl text-neutral-700 font-semibold text-center'>{dialogProps.title}</p>
          <p className='text-base text-center text-neutral-600'>{dialogProps.message}</p>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default LoadingDialog