import axios from "axios";
import { authErrorContent, apiErrorContent } from "./constants";
import { keysToCamelCase } from "../helpers/snake-to-camel";
import useDialogStore from "@/store/useDialogStore";

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFToken"
axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
})

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.headers['content-type'] === 'application/json') {
      response.data = keysToCamelCase(response.data);
      return response.data.data
    }
    return response.data;
  },
  async (error) => {
    // Create a new instance of the store to access the showDialog function
    const dialogStore = useDialogStore.getState();

    if (validateError(error)) {
      // Handle authentication errors
      dialogStore.showDialog('ERROR', { 
        title: authErrorContent.title, 
        message: authErrorContent.message,
        imageUrl: authErrorContent.imageUrl,
        confirmText: authErrorContent.confirmText,
        confirmUrl: window.location.pathname.includes('admin')
          ? `${process.env.REACT_APP_HOME_URL}/asynk/admin/login?returnTo=${window.location.origin}/admin/callback`
          : process.env.REACT_APP_HOME_URL
      });
    } else {
      // Handle other API errors
      dialogStore.showDialog('ERROR', { 
        title: apiErrorContent.title, 
        message: apiErrorContent.message,
        imageUrl: apiErrorContent.imageUrl,
        confirmText: apiErrorContent.confirmText,
        confirmUrl: process.env.REACT_APP_HOME_URL
      });
    }

    return Promise.reject(error);
  }
);

function validateError(error: any) {
  const errorStatus = error.response?.status;
  const errorCode = error.response?.data?.errorCode;
  if (errorStatus === 400 || errorStatus === 401 || errorStatus === 403 || errorCode === 'USER_NOT_EXISTED' || errorCode === 'TALENT_INFO_NOT_FOUND') {
    return true;
  }
  return false;
}

export default axiosInstance;