import React from 'react'

type BaseTabProps = {
    title: string,
    children: React.ReactNode
}

const BaseTabContent: React.FC<BaseTabProps> = (props) => {
  return (
    <div className='flex flex-col gap-2'>
      <h1 className='text-lg text-neutral-700 font-bold'>{props.title}</h1>
      {props.children}
    </div>
  )
}

export default BaseTabContent