import React from 'react'
import { Input, InputProps } from '@/components/ui/input';
import { Label } from '@/components/ui/label';

type TextInputProps = {
    label?: string;
    placeholder: string;
    value: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
} & InputProps & React.RefAttributes<HTMLInputElement>

const TextInput = ({ label, placeholder, value, onChange, ...rest }: TextInputProps) => {
  return (
    <div className='flex flex-col gap-1'>
      {label ? <Label className='text-neutral-700 text-sm'>{label}</Label> : null}
      <Input
        className='bg-white focus:outline-none'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </div>
  )
}

export default TextInput