import useAuthStore from "@/store/useAuthStore";
import { adminLogin } from "@/utils/api/admin";
import { getUserInfo } from "@/utils/api/user";
import { useMutation } from "@tanstack/react-query"

const useAdminLogin = () => {
  const { login } = useAuthStore();
  return useMutation({
    mutationFn: async (req: { token: string }) => {
      await adminLogin(req);
      const userInfo = await getUserInfo();
      login(userInfo);
      return userInfo;
    }
  });
}

export default useAdminLogin