import { useCallback, useEffect, useRef, useState } from 'react'
import SearchInput from '@/components/Input/SearchInput'
import CustomCard from '@/components/customized-ui/card/custom-card'
import TalentInfoCard from '@/components/customized-ui/card/talent-info-card'
import { Separator } from '@/components/ui/separator'
import { IoPersonOutline } from 'react-icons/io5'
import ProfessionalSummaryTab from '@/components/customized-ui/tab/content/professional-summary-tab-content'
import ExperienceTab from '@/components/customized-ui/tab/content/experience-tab-content'
import EducationTab from '@/components/customized-ui/tab/content/education-tab-content'
import TopTenJobMatchesTab from '@/components/customized-ui/tab/content/top-ten-job-matches-tab-content'
import CustomTabs from '@/components/customized-ui/tab/base-tab'
import useTalentSearch from '@/hooks/api/admin/get-talent-search'
import useTalentDetail from '@/hooks/api/admin/get-talent'
import { DetailSkeleton, ListSkeleton } from '@/components/customized-ui/skeleton/job-page-skeleton'
import { TypographyH4 } from '@/components/customized-ui/typography/h4'
import { TypographySmall } from '@/components/customized-ui/typography/small'
import { Button } from '@/components/ui/button'
import Pagination from '@/components/customized-ui/pagination/base'
import { cn } from '@/lib/utils'

const AdminHomePage = () => {
  const messageRef = useRef<HTMLDivElement | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const { data: talents = [], isPending: isPendingTalentSearch, setPage, setKeyword } = useTalentSearch();
  const { query: { data: talentDetail, isPending: isPendingTalentDetail }, setId, } = useTalentDetail();

  const onFetchTalents = useCallback(() => {
    if (talents.length > 0) {
      setId(talents[0].studentId)
    }
  }, [setId, talents])

  useEffect(() => {
    onFetchTalents();
  }, [talents])

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollTop = messageRef.current.scrollHeight;
    }
  }, [talentDetail]);

  const onPageChange = async (pageNumber: number) => {
    setPage(pageNumber-1);
    if (talents.length > 0) {
      setId(talents[0].studentId)
    }
  }

  return (
    <div className='max-w-5xl mx-auto py-4'>
      <form className='flex gap-4' onSubmit={(e) => { e.preventDefault(); setKeyword(searchInput)}}>
        <SearchInput
          placeholder='Search by student ID, names, email address, or phone number'
          value={searchInput}
          onValueChange={(value) => setSearchInput(value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              setKeyword(searchInput);
            }
          }}
        />
        <div className='flex items-center gap-x-2'>
          <Button type='submit' className='bg-primary-500 hover:bg-primary-600'>
            Search
          </Button>
          <button onClick={() => setSearchInput('')} className="w-full text-base text-neutral-500 font-semibold whitespace-nowrap">
            Clear
          </button>
        </div>
      </form>
      {
        !isPendingTalentSearch && talents.length === 0 &&
        <div className='flex flex-col items-center justify-center gap-4 my-4 h-[calc(100vh_-_200px)] bg-white rounded-lg shadow-lg'>
          <TypographyH4>
            No result found
          </TypographyH4>
          <TypographySmall className='text-center text-neutral-500'>
            No results were found to match your search.
            <br/>
            Please consider adjusting the filters or verifying for any spelling errors.
          </TypographySmall>
        </div>
      }
      {
        talents.length > 0 &&
        <div className="grid grid-cols-3 gap-4 my-6">
          {
            isPendingTalentSearch
              ?
              <ListSkeleton />
              :
              <div className="flex flex-col gap-2 col-span-1">
                <h1 className='text-lg font-semibold'>Most Relevant Profiles</h1>
                <p className='text-xs'>Showing {talents.length} results</p>
                {talents.map(({ studentId, email, firstName, lastName }) => (
                  <div onClick={() => {
                    setId(studentId)
                  }}>
                    <TalentInfoCard
                      className={cn('hover:shadow-xl cursor-pointer', email === talentDetail?.talentProfile.talentInfo.email && 'border-2 border-[#F5997D] bg-[#FFF8F6]')}
                      title=""
                      id={studentId}
                      name={firstName + " " + lastName}
                      email={email}
                    />
                  </div>
                ))}
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalItems={talents.length}
                  itemsPerPage={5}
                  onPageChange={onPageChange}
                />
              </div>}
          {isPendingTalentDetail
            ?
            <div className='col-span-2'>
              <DetailSkeleton />
            </div>
            :
            <div className="col-span-2 ">
              <CustomCard title={talentDetail?.talentProfile.talentInfo.firstName + " " + talentDetail?.talentProfile.talentInfo.lastName}
                cardContent={
                  <div className="flex flex-col gap-2">
                    <div className='flex'>
                      <p className='flex gap-1 items-center text-sm'><IoPersonOutline size={14} />{talentDetail?.talentProfile.talentInfo.studentId}</p>
                    </div>
                    <div className='grid grid-cols-3 gap-4'>
                      {[
                        { title: "Email", content: talentDetail?.talentProfile.talentInfo.email },
                        { title: "Contact Number", content: talentDetail?.talentProfile.talentInfo.phone },
                        { title: "Availability", content: talentDetail?.talentProfile.talentInfo.availability === -1 ? 'Available Immediately' : `${talentDetail?.talentProfile.talentInfo.availability} month(s)` },
                        { title: "Key Skills", content: talentDetail?.talentProfile.talentAttributeList.map((v) => v.name).join(', ') },
                        { title: "Years of Study", content: "2023" },
                        { title: "Job Function", content: talentDetail?.talentProfile.talentInfo.jobFunction?.name || "Not Specified" },
                      ].map((info) => (
                        <div>
                          <p className='text-sm text-primary-900 font-semibold'>{info.title}</p>
                          <p className='text-xs text-neutral-600 break-words'>{info.content}</p>
                        </div>
                      ))}
                    </div>
                    <Separator />
                    <CustomTabs
                      tabs={[
                        'Professional Summary',
                        'Experience',
                        'Education',
                        'Top 10 Job Matches'
                      ]}
                      nodes={{
                        'Professional Summary': <ProfessionalSummaryTab summary={talentDetail?.talentProfile.talentInfo.summary || ""} />,
                        'Experience': <ExperienceTab experiences={talentDetail?.talentProfile.talentExperienceList || []} />,
                        'Education': <EducationTab educations={talentDetail?.talentProfile.talentQualificationList || []} />,
                        'Top 10 Job Matches': <TopTenJobMatchesTab jobList={talentDetail?.jobInfoList || []} />
                      }}
                    />
                  </div>
                }
              />
            </div>}
        </div>}
    </div>
  )
}

export default AdminHomePage