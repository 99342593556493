import React from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {SelectOption} from '@/types/common/select-option';

type CustomSelectProps = {
  options: SelectOption[];
  placeholder: string;
  onChange: (option: SelectOption) => void;
  disabled?: boolean;
}

const CustomSelect = ({options, placeholder, onChange, disabled}: CustomSelectProps) => {

  const onValueChange = (value: string) => {
    const option = options.find((option) => option.id === value);
    if (option) {
      onChange(option);
    }
  }

  return (
    <Select onValueChange={onValueChange} disabled={disabled}>
      <SelectTrigger className="w-[180px] bg-white">
        <SelectValue placeholder={placeholder}/>
      </SelectTrigger>
      <SelectContent className='bg-white max-h-80 overflow-auto'>
        {options.map((option) => (
          <SelectItem key={option.id} value={option.id}>
            {option.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default CustomSelect