import { TalentInfo } from "@/types/common/talent";
import { Content } from "pdfmake/interfaces";
import { ContentCreator } from "@/lib/pdf/interface";

export default class SummaryContentCreator implements ContentCreator {
  constructor(private readonly talentInfo: TalentInfo) {}

  async create(): Promise<Content> {
    return [
      { text: this.talentInfo.summary, fontSize: 10, alignment: 'justify', bold: false },
    ];
  }
}