import React, {useCallback, useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import TextInput from '../../components/Input/TextInput'
import Section from '../../components/Section/Section';
import {TalentSkillTable} from '@/components/customized-ui/table/talent-skill-table';
import TipsCard from '@/components/customized-ui/card/tips-card';
import {TalentProfile} from '@/types/common/talent';
import useGetTalentProfile from '@/hooks/api/talent/get-talent-profile';
import {TypographySmall} from '@/components/customized-ui/typography/small';
import EnrichmentInput from '@/components/customized-ui/input/enrichment-input';
import {ResumeGenerator} from '@/lib/pdf/resume-generator';
import {Button} from '@/components/ui/button';
import {TypographyP} from '@/components/customized-ui/typography/p';
import {MonthPicker} from '@/components/customized-ui/date-picker/month-picker';
import TextAreaInput from '@/components/customized-ui/input/textarea-input';
import {useSearchParams} from 'react-router-dom';
import {updateTalentProfile} from '@/utils/api/talent';
import {TypographyP2} from '@/components/customized-ui/typography/p2';
import {useToast} from '@/components/ui/use-toast';
import {Separator} from "@/components/ui/separator";
import { Subheading } from '@/components/customized-ui/typography/subheading';
import { H1 } from '@/components/customized-ui/typography/h1';
import { DownloadIcon, EditIcon, PlusIcon, SaveIcon } from 'lucide-react';
import { getLocalDate } from '@/utils/helpers/date-helper';
import useDialogStore from '@/store/useDialogStore'
import { TypographyCaption } from '@/components/customized-ui/typography/caption';

const ResumeBuilder = () => {
  const {toast} = useToast();
  const {showDialog, hideDialog} = useDialogStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const {data: talentProfile} = useGetTalentProfile();
  const {control, getValues, setValue, reset} = useForm({
    defaultValues: {} as TalentProfile,
  });

  useEffect(() => {
    if (talentProfile) {
      reset(talentProfile);
    }
  }, [talentProfile, reset])

  const onDownloadResume = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await onUpdateTalentProfile();
    const resumeGenerator = new ResumeGenerator(getValues());
    await resumeGenerator.download();
  }

  const onUpdateTalentProfile = async () => {
    await handleUpdateTalentProfile();
  }

  const handleUpdateTalentProfile = useCallback(async () => {
    showDialog(
      'LOADING',
      {
        title: 'Saving Profile',
        message: 'Please wait',
      });
    const updatedTalentProfile = getValues();
    updatedTalentProfile.talentInfo.additionalInfo = JSON.stringify(updatedTalentProfile.talentInfo.additionalInfo) as any;
    await updateTalentProfile(updatedTalentProfile);
    hideDialog();
    toast({
      title: 'Profile Saved Successfully 🎉',
    })
  }, [getValues, hideDialog, showDialog, toast])

  return (
    <div className='relative max-w-5xl mx-auto py-4'>
      {!localStorage.getItem("hideTips") &&
        <div className='fixed top-20 right-20 z-50'>
          <TipsCard className='ml-auto w-96'/>
        </div>}
      <H1>CV 360</H1>
      <Section title='Personal Details' actionButtonChildren={
        <div className='flex items-center justify-center gap-2'>
          <EditIcon size={16} className='text-secondary-800' />
          <TypographyP className="text-secondary-800 text-sm">
            Edit Personal Details
          </TypographyP>
        </div>
      }>
        <div className='flex flex-col gap-4'>
          <div className='grid grid-cols-2 gap-4 mt-4'>
            <Controller
              name='talentInfo.firstName'
              control={control}
              render={({field}) => (
                <TextInput
                  label='First Name'
                  placeholder='e.g. Erica'
                  disabled
                  {...field}
                />
              )}
            />
            <Controller
              name='talentInfo.lastName'
              control={control}
              render={({field}) => (
                <TextInput
                  label='Last Name'
                  placeholder='e.g. Chan'
                  disabled
                  {...field}
                />
              )}
            />
          </div>
          <div className='grid grid-cols-2 gap-4'>
            <Controller
              name='talentInfo.email'
              control={control}
              render={({field}) => (
                <TextInput
                  label='Email Address'
                  placeholder='e.g. erica.chan@gmail.com'
                  disabled
                  {...field}
                />
              )}
            />
            <Controller
              name='talentInfo.phone'
              control={control}
              render={({field}) => (
                <TextInput
                  label='Contact Number'
                  placeholder='e.g. 90846739'
                  disabled
                  {...field}
                />
              )}
            />
            <Controller
              name='talentInfo.linkedinUri'
              control={control}
              render={({field}) => (
                <TextInput
                  label='Linkedin Profile'
                  placeholder='e.g. linkedin.com/in/erica-chan'
                  {...field}
                  disabled
                />
              )}
            />
          </div>
        </div>
      </Section>
      <Section
        title='Personal Summary'
        message='Write 2-4 sentences to introduce yourself - your major, previous projects or jobs, and skills you’re good at!'
        isHideActionButton
      >
        <Controller
          control={control}
          name='talentInfo.summary'
          render={({field}) => (
            <EnrichmentInput
              enrichmentType='SUMMARY'
              value={field.value}
              onChange={field.onChange}
              jobFunctionId={searchParams.get('id') || getValues('talentInfo.jobFunction.id')}
            />
          )}
        />
      </Section>
      <Section
        title='Experiences'
        message={<p>Enhance the experience description from your <span className="underline cursor-pointer" onClick={() => {
          window.location.href = process.env.REACT_APP_PROFILE_URL || ''
        }}>CU Job Link</span> profile.</p>}
        actionButtonChildren={
          <div className="flex items-center justify-center gap-2">
            <PlusIcon size={16} className='text-secondary-800' />
            <TypographyP className="text-secondary-800 text-sm">
              Add Experience
            </TypographyP>
          </div>
        }
      >
        {
          getValues('talentExperienceList')?.length === 0 &&
          <TypographySmall className='text-neutral-600'>
            You have not added any experience yet.
          </TypographySmall>
        }
        {
          getValues('talentExperienceList')?.map((experience, index) => (
            <div className='flex flex-col gap-4'>
              <div className='grid grid-cols-2 gap-4'>
                <TypographyP2 className="col-span-2 font-medium">{`Experience ${index + 1}`}</TypographyP2>
                <Controller
                  name={`talentExperienceList.${index}.title`}
                  control={control}
                  render={({field}) => (
                    <TextInput
                      label='Job Title'
                      placeholder='e.g. Marketing Strategist'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`talentExperienceList.${index}.companyName`}
                  control={control}
                  render={({field}) => (
                    <TextInput
                      label='Company'
                      placeholder='e.g. Google'
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='grid grid-cols-2 gap-4'>
                <Controller
                  name={`talentExperienceList.${index}.startedAt`}
                  control={control}
                  render={({field}) => (
                    <MonthPicker
                      label='Started Date'
                      selectedDate={field.value ? getLocalDate(new Date(field.value)) : null}
                      onMonthSelect={async (date) => {
                        field.onChange(date === null ? null : date.toISOString());
                      }}
                    />
                  )}
                />
                <Controller
                  name={`talentExperienceList.${index}.endedAt`}
                  control={control}
                  render={({field}) => (
                    <MonthPicker
                      label='Ended Date'
                      selectedDate={field.value ? getLocalDate(new Date(field.value)) : null}
                      onMonthSelect={async (date) => {
                        field.onChange(date === null ? null : date.toISOString());
                      }}
                      isEndDate
                    />
                  )}
                />
              </div>
              <Controller
                control={control}
                name={`talentExperienceList.${index}.description`}
                render={({field}) => (
                  <EnrichmentInput
                    label='Key Highlights'
                    enrichmentType='EXPERIENCE'
                    value={field.value}
                    onChange={field.onChange}
                    jobFunctionId={searchParams.get('id') || getValues('talentInfo.jobFunction.id')}
                  />
                )}
              />
              {index < getValues("talentExperienceList").length - 1 ? <Separator/> : null}
            </div>
          ))
        }
      </Section>
      <Section
        title='Extra-Curricular Activities'
        message={<p>Activities from your <span className="underline cursor-pointer" onClick={() => {
          window.location.href = process.env.REACT_APP_PROFILE_URL || ''
        }}>CU Job Link</span> profile will be used in the following section.</p>}
        actionButtonChildren={
          <div className="flex items-center justify-center gap-2">
            <PlusIcon size={16} className='text-secondary-800' />
            <TypographyP className="text-secondary-800 text-sm">
              Add Extra-Curricular Activity
            </TypographyP>
          </div>
        }
      >
        {
          getValues('talentExtraCurricularActivityList')?.length === 0 &&
          <TypographySmall className='text-neutral-600'>
            You have not added any extra curricular activity yet.
          </TypographySmall>
        }
        {
          getValues('talentExtraCurricularActivityList')?.map((experience, index) => (
            <div className='flex flex-col gap-4'>
              <div className='grid grid-cols-2 gap-4'>
                <Subheading
                  className="col-span-2 font-medium text-neutral-800"
                >
                  {`Extra-Curricular Activity ${index + 1}`}
                </Subheading>
                <Controller
                  name={`talentExtraCurricularActivityList.${index}.title`}
                  control={control}
                  render={({field}) => (
                    <TextInput
                      label='Title'
                      placeholder='e.g. President of Student Council'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`talentExtraCurricularActivityList.${index}.institution`}
                  control={control}
                  render={({field}) => (
                    <TextInput
                      label='Institution'
                      placeholder='e.g. Hong Kong University of Science and Technology'
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='grid grid-cols-2 gap-4'>
                <Controller
                  name={`talentExtraCurricularActivityList.${index}.startedAt`}
                  control={control}
                  render={({field}) => (
                    <MonthPicker
                      label='Started Date'
                      selectedDate={field.value ? getLocalDate(new Date(field.value)) : null}
                      onMonthSelect={async (date) => {
                        field.onChange(date === null ? null : date.toISOString());
                      }}
                    />
                  )}
                />
                <Controller
                  name={`talentExtraCurricularActivityList.${index}.endedAt`}
                  control={control}
                  render={({field}) => (
                    <MonthPicker
                      label='Ended Date'
                      selectedDate={field.value ? getLocalDate(new Date(field.value)) : null}
                      onMonthSelect={async (date) => {
                        field.onChange(date === null ? null : date.toISOString());
                      }}
                      isEndDate
                    />
                  )}
                />
              </div>
              <Controller
                control={control}
                name={`talentExtraCurricularActivityList.${index}.description`}
                render={({field}) => (
                  <TextAreaInput
                    label='Description'
                    value={field.value || ''}
                    onChange={field.onChange}
                    placeholder='e.g. Organized a charity event that raised $10,000 for the local community'
                  />
                )}
              />
            </div>
          ))
        }
      </Section>
      <Section
        title='Education'
        message='Got more degrees to talk about? Update in your profile page.'
        actionButtonChildren={
          <div className="flex items-center justify-center gap-2">
            <PlusIcon size={16} className='text-secondary-800' />
            <TypographyP className="text-secondary-800 text-sm">
              Add Education
            </TypographyP>
          </div>
        }
      >
        {
          getValues('talentQualificationList')?.length === 0 &&
          <TypographyP2 className='text-neutral-600'>
            You have not added any education yet.
          </TypographyP2>
        }
        <div className='flex flex-col gap-4'>
          {getValues('talentQualificationList')?.map((_, index) => (
            <div className='grid grid-cols-2 gap-4'>
              <Subheading className="col-span-2 font-medium text-neutral-800">{`Education ${index + 1}`}</Subheading>
              <Controller
                name={`talentQualificationList.${index}.major`}
                control={control}
                render={({field}) => (
                  <TextInput
                    label='Qualification Title'
                    placeholder='e.g. Bachelor of Engineering in Electronics'
                    value={field.value || ''}
                    onChange={field.onChange}
                  />
                )}
              />
              <Controller
                name={`talentQualificationList.${index}.institution`}
                control={control}
                render={({field}) => (
                  <div className='flex flex-col gap-1'>
                    <TextInput
                      label='Institution'
                      placeholder='e.g. The Chinese University of Hong Kong'
                      {...field}
                    />
                    <TypographyCaption className='text-neutral-600 italic'>
                      Use official name: The Chinese University of Hong Kong
                    </TypographyCaption>
                  </div>
                )}
              />
              <Controller
                name={`talentQualificationList.${index}.type`}
                control={control}
                render={({field}) => (
                  <TextInput
                    label='Education Level'
                    placeholder="Bachelor's Degree"
                    {...field}
                  />
                )}
              />
              <div className='grid grid-cols-2 gap-4'>
                <Controller
                  name={`talentQualificationList.${index}.startedAt`}
                  control={control}
                  render={({field}) => (
                    <MonthPicker
                      label='Started Date'
                      selectedDate={field.value ? getLocalDate(new Date(field.value)) : null}
                      onMonthSelect={async (date) => {
                        field.onChange(date === null ? null : date.toISOString());
                      }}
                    />
                  )}
                />
                <Controller
                  name={`talentQualificationList.${index}.endedAt`}
                  control={control}
                  render={({field}) => (
                    <MonthPicker
                      label='Ended Date'
                      selectedDate={field.value ? getLocalDate(new Date(field.value)) : null}
                      onMonthSelect={async (date) => {
                        field.onChange(date === null ? null : date.toISOString());
                      }}
                      isEndDate
                    />
                  )}
                />
              </div>
              <div className='col-span-2'>
                <Controller
                  name={`talentQualificationList.${index}.description`}
                  control={control}
                  render={({field}) => (
                    <TextAreaInput
                      label='Description'
                      value={field.value || ''}
                      onChange={field.onChange}
                      placeholder='e.g. GPA 3.7/4.0'
                    />
                  )}
                />
              </div>
              {index < getValues("talentQualificationList").length - 1 ? <Separator/> : null}
            </div>
          ))}
        </div>
      </Section>
      <Section
        title='Qualification'
        message={<p>Add more certificates in your <span className="underline cursor-pointer" onClick={() => {
          window.location.href = process.env.REACT_APP_PROFILE_URL || ''
        }}>CU Job Link</span> profile.</p>}
        actionButtonChildren={
          <div className="flex items-center justify-center gap-2">
            <PlusIcon size={16} className='text-secondary-800' />
            <TypographyP className="text-secondary-800 text-sm">
              Add Certification
            </TypographyP>
          </div>
        }
      >
        {
          getValues('talentCertificationList')?.length === 0 &&
          <TypographyP2 className='text-neutral-600'>
            You have not added any certification yet.
          </TypographyP2>
        }
        <div className='flex flex-col gap-4'>
          {getValues('talentCertificationList')?.map((certification, index) => (
            <div className='grid grid-cols-2 gap-2'>
              <Subheading className="col-span-2 text-neutral-800">{`Certificate ${index + 1}`}</Subheading>  
              <Controller
                name={`talentCertificationList.${index}.name`}
                control={control}
                render={({field}) => (
                  <TextInput
                    label='Certification Title'
                    placeholder='e.g. AWS Certified Solutions Architect'
                    {...field}
                  />
                )}
              />
              <Controller
                name={`talentCertificationList.${index}.authority`}
                control={control}
                render={({field}) => (
                  <TextInput
                    label='Issuing Organization'
                    placeholder='e.g. Amazon'
                    {...field}
                  />
                )}
              />
              <Controller
                name={`talentCertificationList.${index}.awardedAt`}
                control={control}
                render={({field}) => (
                  <MonthPicker
                    label='Awarded Date'
                    selectedDate={field.value ? getLocalDate(new Date(field.value)) : null}
                    onMonthSelect={async (date) => {
                      field.onChange(date === null ? null : date.toISOString());
                    }}
                  />
                )}
              />
              <Controller
                name={`talentCertificationList.${index}.description`}
                control={control}
                render={({field}) => (
                  <TextInput
                    label='Description'
                    placeholder='e.g. AWS Certified Solutions Architect - Associate'
                    {...field}
                  />
                )}
              />
            </div>
          ))}
        </div>
      </Section>
      <Section
        title='Skills & Other Interests'
        actionButtonChildren={
          <div className="flex items-center justify-center gap-2">
            <PlusIcon size={16} className='text-secondary-800' />
            <TypographyP className="text-secondary-800 text-sm">
              Add Skill
            </TypographyP>
          </div>
        }
      >
        <TypographyP2 className='text-neutral-600'>Highlight some skills you possess. Try to mention less
          than 6 skills per category.</TypographyP2>
        <TalentSkillTable
          talentAttributeList={getValues('talentAttributeList')}
          setTalentAttributeList={(value) => setValue('talentAttributeList', value)}
          additionalInfo={getValues('talentInfo.additionalInfo.attribute')}
          setAdditionalInfo={(value) => setValue('talentInfo.additionalInfo.attribute', value)}
        />
      </Section>
      <div className='flex justify-end mt-4'>
        <div className='flex items-center gap-4'>
          <Button variant='ghost' type='button' onClick={onUpdateTalentProfile}
            className="flex items-center justify-center gap-2 w-40 py-2 border border-primary-800">
            <SaveIcon size={16} className='text-primary-800' />
            <TypographyP className="text-primary-800 text-sm">
              Save Profile
            </TypographyP>
          </Button>
          <Button variant='default' type='button' onClick={onDownloadResume}
            className="flex items-center justify-center gap-2 py-2 border bg-primary-800 hover:bg-primary-900">
            <DownloadIcon size={16} />
            <TypographyP className="text-white text-sm">
              Download Resume (PDF Format)
            </TypographyP>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ResumeBuilder